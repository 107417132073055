export { default as ChangePasswordDialog } from './ChangePasswordDialog';

export const isValidPassword = (pwd: string): boolean => {
  return (
    pwd.length > 7 &&
    pwd.length < 129 &&
    /[A-Z]/.test(pwd) &&
    /[a-z]/.test(pwd) &&
    /[^a-z,A-Z]/.test(pwd)
  );
};
