import { object, array, string, StringSchema, mixed } from 'yup';
import { NewCampaign, Section } from './types';
import moment from 'moment';

const max_message_length = 500;

export const prepareValidationSchema = (languages: string[]) => {
  const localizableMessageObjectDef: { [key: string]: StringSchema } = {};
  const localizableChangeMessageObjectDef: { [key: string]: StringSchema } = {};
  const localizableContentObjectDef: { [key: string]: StringSchema } = {};
  languages.forEach((c) => {
    localizableMessageObjectDef[c] = string()
      .strict(true)
      .max(max_message_length, `Maximum length of message is ${max_message_length} characters.`);
    localizableChangeMessageObjectDef[c] = string().strict(true);
    localizableContentObjectDef[c] = string().strict(true);
  });

  const localizableMessageObjectSchema = object(localizableMessageObjectDef);
  const localizableChangeMessageObjectSchema = object(localizableChangeMessageObjectDef);
  const localizableContentObjectSchema = object(localizableContentObjectDef);

  const schema = object<NewCampaign>().shape({
    productbenefits: array().of(string()).min(1, 'Please select at least one item.').required(),
    data: object()
      .shape({
        [Section.message]: localizableMessageObjectSchema
          .nullable()
          .test(
            'allLanguagesSpecified',
            'All languages must be specified or be empty.',
            (field) => {
              if (!field) {
                return true;
              }

              // Find a field that has at least one language defined, but not all
              const filledInLanguageCount = languages.filter((lang) => !!field[lang]).length;
              return filledInLanguageCount === 0 || filledInLanguageCount === languages.length;
            },
          ),
        [Section.content]: localizableContentObjectSchema
          .nullable()
          .test(
            'allLanguagesSpecified',
            'All languages must be specified or be empty.',
            (field) => {
              if (!field) {
                return true;
              }

              // Find a field that has at least one language defined, but not all
              const filledInLanguageCount = languages.filter((lang) => !!field[lang]).length;
              return filledInLanguageCount === 0 || filledInLanguageCount === languages.length;
            },
          ),
        [Section.changeMessage]: localizableChangeMessageObjectSchema
          .nullable()
          .test(
            'allLanguagesSpecified',
            'All languages must be specified or be empty.',
            (field) => {
              if (!field) {
                return true;
              }

              // Find a field that has at least one language defined, but not all
              const filledInLanguageCount = languages.filter((lang) => !!field[lang]).length;
              return filledInLanguageCount === 0 || filledInLanguageCount === languages.length;
            },
          )
          .test(
            'allHaveChangeMessagePlaceholder',
            'All languages must have change message placeholder "%@".',
            (field) => {
              if (!field) {
                return true;
              }

              // Find a field that has at least one language defined, but not all
              const filledInLanguageCount = languages.filter((lang) => !!field[lang]).length;
              if (filledInLanguageCount === 0) {
                return true;
              }

              const langsWithPlaceholderCount = languages.filter((lang) => {
                const f = field[lang];
                return typeof f !== 'undefined' && f.indexOf('%@') >= 0;
              }).length;

              return langsWithPlaceholderCount === languages.length;
            },
          ),
      })
      .test('contentAndMessage', 'One of sections Message or Content must be defined.', (value) => {
        if (!value) {
          return false;
        }

        const res = value[Section.content] !== null || value[Section.message] !== null;
        return res;
      }),
    date: mixed()
      .required('Please select start date.')
      .test(
        'startDate',
        'The campaign can start no earlier than 15 minutes from the current time.',
        (value) => {
          const max = moment().utc().add(16, 'minutes');
          return value && max.isBefore(value);
        },
      ),
  });

  return schema;
};
