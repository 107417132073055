/* eslint-disable react/display-name */
import React from 'react';

import Star from '@material-ui/icons/Star';

import logo from '../../assets/logo.png';
import {
  prepareButton,
  prepareCancelButton,
  prepareImage,
  prepareUploadButton,
} from '../../components/table/createComplexItem';

const changeLogoHeading = {
  name: 'Change logo on your passes',
  Icon: () => <Star />,
};

function useSettingsData() {
  const ChooseNewImage = prepareUploadButton([{ name: 'Choose new image' }]);
  const Upload = prepareButton([{ name: 'Upload' }]);
  const Logo = prepareImage(logo);

  const changeLogoData = {
    head: [],
    body: [
      ['Current Logo', Logo, ''],
      ['Change Logo', ChooseNewImage, Upload],
    ],
  };

  const Cancel = prepareCancelButton([{ name: 'Cancel changes' }]);
  const Save = prepareButton([{ name: 'Save changes' }], { color: 'secondary' });

  const cancelData = {
    head: [],
    body: [['', Cancel, Save]],
  };

  return { changeLogoData, cancelData };
}

export { useSettingsData, changeLogoHeading };
