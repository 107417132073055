import React from 'react';
import { Campaign } from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { CampaignStartDate } from './CampaignStartDate';
import { CampaignStatus } from './CampaignStatus';
import { CampaignDialog } from './CampaignDialog';
import Issuer from '../../utils/api/Issuer';
import { TextEllipsis } from '../common';

const prepareCampaignCols = (c: Campaign, issuer: Issuer) => {
  return [
    <CampaignStartDate key="datetime" campaign={c} />,
    <span key="name">{issuer.getProductbenefitName(c.getProductbenefitId())}</span>,
    <TextEllipsis key="content" text={c.getContent()?.getDefault() || ''} />,
    <TextEllipsis key="msg" text={c.getMessage()?.getDefault() || ''} />,
    <CampaignStatus key="status" status={c.getStatus()} />,
  ];
};

export { CampaignStartDate, CampaignStatus, prepareCampaignCols, CampaignDialog };
