import React from 'react';
import Wrapper from '../../components/Wrapper';
import { ROUTES } from '../../constants';
import Title from '../../components/common/Title';
import { CreateCampaignHOC } from '../../components/spec/createCampaign';
import { useHistory } from 'react-router-dom';

function CampaignsCreate() {
  const history = useHistory();
  const onFinnish = () => {
    // history.push(ROUTES.campaignsCreate.path);
    history.goBack();
  };
  return (
    <Wrapper selectedKey={ROUTES.campaignsCenter.key}>
      <Title title="Create new campaign" dataTestId="campaigns-center-id" />
      <CreateCampaignHOC onFinish={onFinnish} />
    </Wrapper>
  );
}

export default CampaignsCreate;
