import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

type CircleCounterProps = { total: number; current: number; title: string; color: string };

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    valueContainer: {
      margin: 10,
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: 200,
      height: 100,
      fontSize: 28,
    },
    values: {
      padding: 10,
    },
    progressBarValue: { height: 20 },
    progressBar: {
      height: 20,
      width: '100%',
      background: 'lightgrey',
    },
  }),
);

export const BarCounter = ({ total, current, color, title }: CircleCounterProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.valueContainer}>
        <div className={classes.values}>
          {current}/{total}
        </div>
        <div className={classes.progressBar}>
          <div
            className={classes.progressBarValue}
            style={{ background: color, width: `${(current / total) * 100}%` }}
          ></div>
        </div>
      </div>
      {title}
    </div>
  );
};
