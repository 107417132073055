import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { getColorForIndex } from '../../../utils';
import ReportTitle from '../../report/ReportTitle';
import { BarCounter } from '../../common';
import { useProductBenefitUpdateStats } from '../../../utils/report';
import { ProductBenefitUpdateStats } from '../../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { useIssuer } from '../../../hooks';

const useCampaignStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  }),
);

export const ProductBenefitUpdateConsumption = (): JSX.Element => {
  const { issuer } = useIssuer();

  const classes = useCampaignStyles();

  const { pending, data } = useProductBenefitUpdateStats();

  if (pending || !issuer) {
    return <div />;
  }

  return (
    <div>
      <ReportTitle
        title="Updates consumption"
        helpText={
          'This section displays the number of consumed update operations, that are processed via the Campaign Center. Updates, which was provided by another way (i.e. via API) are not included.'
        }
      />
      <div className={classes.container}>
        {data?.getProductbenefitsList().map((p: ProductBenefitUpdateStats, index: number) => {
          const total = p.getLimit();
          const current = p.getConsumed();
          const label = issuer.getProductbenefitName(p.getId());

          return (
            <BarCounter
              key={`${index}`}
              color={getColorForIndex(index)}
              title={label}
              current={current}
              total={total}
            />
          );
        })}
      </div>
    </div>
  );
};
