import { Campaign } from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { TextDateTime } from '../common';

type StartDateProps = { campaign: Campaign };
const StartDate = (props: StartDateProps, ref: React.Ref<HTMLElement>) => {
  const tooltip = moment(props.campaign.getSendTime()?.toDate()).fromNow();
  return (
    <Tooltip title={tooltip}>
      <TextDateTime ref={ref} key="start" date={props.campaign.getSendTime()?.toDate()} />
    </Tooltip>
  );
};

export const CampaignStartDate = React.forwardRef<HTMLElement, StartDateProps>(StartDate);
