import JSZip from 'jszip';
import bwipjs from 'bwip-js';

export const createPng = (content: string): string => {
  const canvas = document.createElement('canvas');
  bwipjs.toCanvas(canvas, {
    bcid: 'qrcode', // Barcode type
    text: content, // Text to encode
    scale: 3,
  });
  return canvas.toDataURL('image/png');
  //return canvas.toBlob()
};

const dataUrlToBase64 = (uri: string): string => {
  const idx = uri.indexOf('base64,') + 'base64,'.length; // or = 28 if you're sure about the prefix
  const content = uri.substring(idx);
  return content;
};

export const createQrCodesZip = async (content: string): Promise<Blob> => {
  const zip = new JSZip();
  const img = zip.folder('qrcodes');
  img?.file('qrcode.png', dataUrlToBase64(createPng(content)), { base64: true });
  const file = await zip.generateAsync({ type: 'blob' });
  return file;
};

export const downloadBlob = (blob: Blob, name: string) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob)
    return window.navigator.msSaveOrOpenBlob(blob);

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = data;
  link.download = name;

  // this is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
};
