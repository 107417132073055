import React from 'react';
import * as chartjs from 'chart.js';
import { Bar } from 'react-chartjs-2';

export type ChartDataFunction<T extends chartjs.ChartData> = (element: HTMLElement) => T;
export type ChartData<T extends chartjs.ChartData> = ChartDataFunction<T> | T;

interface Props {
  data: ChartData<chartjs.ChartData>;
  width?: number;
  height?: number;
  resposive?: boolean;
}

const options = {
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
};

function Chart({ data, width, height, resposive }: Props) {
  return (
    <Bar
      height={height}
      width={width}
      data={data}
      options={{
        responsive: resposive,
        ...options,
        legend: { display: false, position: 'bottom', labels: { boxWidth: 12 }, align: 'center' },
      }}
    />
  );
}

export default Chart;
