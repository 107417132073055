import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

export type HelpTextProps = {
  content: JSX.Element | string;
};

const HelpText = (props: HelpTextProps) => {
  return (
    <Tooltip title={<Typography variant="body2">{props.content}</Typography>}>
      <HelpIcon style={{ opacity: 0.4, marginLeft: 4 }} />
    </Tooltip>
  );
};

export default HelpText;
