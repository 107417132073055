import { useEffect, useState } from 'react';
import { GetTemplateLayoutResponse } from '../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { useUser } from '../hooks';

export const useTemplateLayout = (id: string) => {
  const { client } = useUser();
  const [data, setData] = useState<GetTemplateLayoutResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        if (client) {
          const result = await client.getTemplateLayout(id);
          setData(result);
        } else {
          throw new Error('Grpc client is not available');
        }
      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [id]);

  return { data, isLoading, isError };
};
