import React, { PropsWithChildren } from 'react';
import ApiClient from '../utils/api';
import Viewer from '../utils/api/Viewer';
import { useSnackbar } from 'notistack';

type State = {
  viewer: Viewer;
  client: ApiClient;
};

type Context = State & {
  signOut: () => void;
};

export const UserContext = React.createContext<Partial<Context>>({});

export const UserProvider: React.FC<PropsWithChildren<{ client: ApiClient; viewer: Viewer }>> = ({
  children,
  client,
  viewer,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const signOut = () => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      if (xhr.status === 200) {
        window.location.reload();
      } else {
        console.error(xhr.responseText);
        enqueueSnackbar('Logout failed', { variant: 'error' });
      }
    };
    xhr.open('POST', '/auth/logout');
    xhr.send();
  };
  return (
    <UserContext.Provider value={{ client, viewer, signOut }}>{children}</UserContext.Provider>
  );
};
