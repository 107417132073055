import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { ErrorText } from '../../';

export type ErrorMessageProps = { path: string };

export const ErrorMessage = (props: ErrorMessageProps) => {
  const { errors, submitCount } = useFormikContext();
  const error = getIn(errors, props.path, null);
  return (
    <div>
      {error && typeof error === 'string' && submitCount > 0 && <ErrorText error={error} />}
    </div>
  );
};
