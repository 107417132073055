import React, { useLayoutEffect, useState } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import Login from '../components/Login';
import { IssuerProvider, UserProvider } from '../contexts';
import ApiClient, { getCsrf } from '../utils/api';
import { GetViewerResponse } from '../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import Issuer from '../utils/api/Issuer';
import Viewer from '../utils/api/Viewer';

function PrivateRoute({ path, component }: RouteProps) {
  const history = useHistory();
  const [pending, setPending] = useState(true);
  const [viewer, setViewer] = useState<GetViewerResponse | null>(null);

  const csrf = getCsrf();
  const client = new ApiClient(csrf || '');

  useLayoutEffect(() => {
    let didCancel = false;
    // check if user is signed in
    if (!viewer) {
      const check = async () => {
        try {
          // if no csrf -> is not signed
          if (csrf != null && csrf !== '') {
            const viewer = await client.getViewer();
            if (!didCancel) {
              setViewer(viewer);
            }
          }
        } catch (e) {
          console.error(e);
        }
        if (!didCancel) {
          setPending(false);
        }
      };
      check();
    } else {
      if (!didCancel) {
        setPending(false);
      }
    }
    return () => {
      didCancel = true;
    };
  }, [history, csrf, client, viewer]);

  if (pending) return <LinearProgress color="secondary" />;

  return viewer ? (
    <IssuerProvider issuer={new Issuer(viewer)}>
      <UserProvider viewer={new Viewer(viewer)} client={client}>
        <Route path={path} component={component} />
      </UserProvider>
    </IssuerProvider>
  ) : (
    <Login />
  );
}

export default PrivateRoute;
