import React from 'react';
import { ROUTES } from '../../constants';
import Title from '../../components/common/Title';
import Wrapper from '../../components/Wrapper';
import { Table } from '../../components';
import { useIssuer } from '../../hooks';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { prepareIcon, prepareButton, FontSize } from '../../components/table/createComplexItem';
import { DownloadableFile } from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { TableData } from '../../components/Table';

const ListIcon = ({ fontSize }: { fontSize?: FontSize }) => <PostAddIcon fontSize={fontSize} />;
const fontSize = 'large';

const IconList = prepareIcon(ListIcon, fontSize);

function MarketingMaterials() {
  const { issuer } = useIssuer();

  const data: TableData = {
    head: [],
    body: [],
  };
  if (issuer.getDownloads()) {
    data.body = issuer.getDownloads().map((f: DownloadableFile) => [
      IconList,
      f.getName(),
      prepareButton([
        {
          name: 'Download',
          onClick: () => {
            window.open(f.getDownloadUrl(), '_blank');
          },
        },
      ]),
    ]);
  }

  return (
    <Wrapper selectedKey={ROUTES.marketingMaterials.key}>
      <Title title="Marketing materials" dataTestId="marketing-materials-id" />
      <Table data={data} fallback="No materials available" />
    </Wrapper>
  );
}

export default MarketingMaterials;
