import React from 'react';
import Doughnut, { ChartData } from '../common/charts/Doughnut';
import ReportTitle from './ReportTitle';
import { usePlatformStats } from '../../utils/report';
import { getColorForIndex } from '../../utils';
import * as chartjs from 'chart.js';
import { useControlStyles } from './styles';

export const OperatingSystem = (): JSX.Element => {
  const { pending, data } = usePlatformStats();
  const classes = useControlStyles();
  if (pending) {
    return <div />;
  }

  const graphdata: ChartData<chartjs.ChartData> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [getColorForIndex(0), getColorForIndex(1)],
        hoverBackgroundColor: [getColorForIndex(0), getColorForIndex(1)],
      },
    ],
  };

  if (data) {
    graphdata?.labels?.push('ios');
    graphdata?.labels?.push('android');
    if (graphdata?.datasets && graphdata?.datasets.length > 0) {
      graphdata.datasets[0].data = [data.getIos(), data.getAndroid()];
    }
  }

  return (
    <React.Fragment>
      <ReportTitle
        title="Operating system"
        helpText={'The operating system of the device in which a pass was registered'}
      />
      <div className={classes.root}></div>
      <Doughnut text="64% ios, 36% android" data={graphdata} />
    </React.Fragment>
  );
};
