import { makeStyles, Theme } from '@material-ui/core';

export const useControlStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 40,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  empty: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));
