import React, { CSSProperties } from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Item from '@material-ui/core/Button';

import { ActionButton } from './ActionButton';

export type FontSize = 'inherit' | 'default' | 'small' | 'large';
type OnClick = (id?: string) => void;
type Bonus = { id?: string; color?: 'primary' | 'secondary' | 'inherit' | 'default' };

type Item = { name: string; onClick?: OnClick };
type Items = Array<Item>;

export const ActionButtons = ({
  buttons,
  bonus,
  style,
}: {
  buttons: Items;
  bonus?: Bonus;
  style?: CSSProperties;
}) => {
  const buttonsLastOmit = buttons.slice(0, buttons.length - 1);
  const lastButton = buttons[buttons.length - 1];
  // const classes = useStyles();

  return (
    <Grid container alignItems="center" style={{ display: 'inline-flex', width: 'fit-content' }}>
      {buttonsLastOmit.map((button, index) => (
        <>
          <ActionButton key={`${index}`} {...button} id={bonus?.id} style={style} />
          <Divider orientation="vertical" flexItem style={{ marginLeft: 6, marginRight: 6 }} />
        </>
      ))}
      <ActionButton {...lastButton} id={bonus?.id} style={style} />
    </Grid>
  );
};
