import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 100,
      display: 'block',
    },
  }),
);

export const TextEllipsis = (props: { text: string }): JSX.Element => {
  const classes = useStyles();
  return <span className={classes.root}>{props.text}</span>;
};
