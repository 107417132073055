import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { helpers } from '../constants';
import TableFooter from '@material-ui/core/TableFooter';

type FunctionElement = () => JSX.Element;
type Item = React.ReactNode;
type Options = { rows?: Array<number>; style: React.CSSProperties }; // ❗ CAREFUL ABOUT EMPTY ROWS ❗
export type TableData = {
  head: Array<Item>;
  body: Array<Array<Item>>;
  options?: Options;
};

interface Props {
  data: TableData;
  heading?: {
    name: string;
    Icon?: FunctionElement;
  };
  lastRight?: boolean;
  percentageWidth?: boolean;
  fallback?: React.ReactNode;
  pending?: boolean;
  onReadMore?: () => void;
  more?: React.ReactNode;
}

const useStyles = makeStyles({
  root: {},
  tableContainer: {
    width: 'auto',
    minWidth: 600,
  },
  table: {
    border: 'none',
  },
  cellHead: {
    paddingLeft: 0,
    border: 'none',
    fontWeight: 'bold',
  },
  cellBody: {
    paddingLeft: 0,
    border: 'none',
  },
  icon: {
    display: 'flex',
    marginRight: 10,
  },
  heading: {
    marginBottom: 1,
    marginTop: 2,
  },
  fallbackContainer: {
    marginBottom: 1,
    marginTop: 2,
  },
  readMore: {
    color: '#000',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  readMoreCell: {
    border: 'none',
    textAlign: 'center',
  },
});

function handleComplexItem(item: Item, style?: React.CSSProperties) {
  if (typeof item === 'function') {
    return item(style);
  }
  return item;
}

//  Primary goal 🏁 is handling Styling 🌈
// ❗ EMPTY ROWs => ALL ❗
function handleOptions(options?: Options) {
  // Check if opinions ( 🌈 style, [1️⃣, 2️⃣, ...] rows )
  if (helpers.isDefined(options)) {
    // Check if special rows defined
    if (helpers.isDefined(options.rows) && options.rows.length > 0) {
      return {
        getRowStyle: (index: number) =>
          options.rows?.find((e) => e - 1 === index) ? options.style : {},
      };
    }
    return { getRowStyle: () => options.style };
  }
  return { getRowStyle: () => ({}) };
}

function SimpleTable({
  data,
  heading,
  lastRight,
  percentageWidth,
  fallback,
  pending,
  onReadMore,
  more,
}: Props) {
  const classes = useStyles();

  const bodyElementsCount = data.body.length > 0 ? data.body[0].length : data.head.length;
  const itemPercentageWidth = percentageWidth ? `${100 / bodyElementsCount}%` : 'auto';
  const { getRowStyle } = handleOptions(data.options);

  return (
    <div className={classes.root}>
      {heading?.name ? (
        <Grid className={classes.heading} container direction="row" alignItems="center">
          {heading.Icon ? (
            <Grid item className={classes.icon}>
              <heading.Icon />
            </Grid>
          ) : null}
          <Grid item>
            <Typography variant="h5" gutterBottom className={classes.heading}>
              {heading.name}
            </Typography>
          </Grid>
        </Grid>
      ) : null}

      {data.body.length === 0 && !pending && (
        <div className={classes.fallbackContainer}>{fallback || 'No items available'}</div>
      )}

      {!(data.body.length === 0 && !pending) && (
        <React.Fragment>
          <TableContainer classes={{ root: classes.tableContainer }}>
            <Table size="small" aria-label="simple table" className={classes.table}>
              <TableHead>
                <TableRow>
                  {data.head.map((cell, index: number) => {
                    return (
                      <TableCell
                        align="left"
                        key={index}
                        classes={{ root: classes.cellHead }}
                        style={{ width: itemPercentageWidth }}
                      >
                        {cell}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.body.map((row, index: number) => {
                  const rowStyle = getRowStyle(index);
                  return (
                    <TableRow key={index}>
                      {row.map((cell, innerIndex: number) => {
                        // Handle last element🏁 alignment
                        const elementAlign =
                          bodyElementsCount - 1 === innerIndex && lastRight ? 'right' : 'left';

                        return (
                          <TableCell
                            style={{ width: itemPercentageWidth, ...rowStyle }}
                            align={elementAlign}
                            key={innerIndex}
                            classes={{ root: classes.cellBody }}
                          >
                            {handleComplexItem(cell, rowStyle)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
              {onReadMore && (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={bodyElementsCount} className={classes.readMoreCell}>
                      <span onClick={onReadMore} className={classes.readMore}>
                        {more || 'read more'}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </React.Fragment>
      )}

      {pending && <div className={classes.fallbackContainer}>...pending....</div>}
    </div>
  );
}

export default SimpleTable;
