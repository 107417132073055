import React, { useMemo } from 'react';
import { ActionButton, Table, LinkButton } from '../../../components';
import { useIssuer } from '../../../hooks';
import {
  Productbenefit,
  Benefit,
  Product,
  OriginMethod,
} from '../../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { TableData } from '../../../components/Table';
import { createQrCodesZip, downloadBlob } from '../../../utils/files';

export function ActiveProductTable() {
  const { issuer } = useIssuer();

  const data = useMemo(() => {
    const productsData: TableData = {
      head: [''],
      body: [],
    };

    const benefits = (issuer.getBenefits() || []).filter((pb: Benefit) => pb.getActive());
    const products = (issuer.getProductsList() || []).filter((pb: Product) => pb.getActive());

    const productBenefits = (issuer.getProductbenefitsList() || []).filter(
      (pb: Productbenefit) =>
        pb.getActive() && pb.getOriginMethod() == OriginMethod.ORIGIN_METHOD_ISSUE,
    );

    benefits.forEach((b: Benefit) => {
      if (productBenefits.filter((pb) => pb.getBenefitId() === b.getId()).length > 0) {
        productsData.head.push(b.getName()?.getDefault().toLocaleUpperCase() || b.getId());
      }
    });

    for (const p of products) {
      if (productBenefits.filter((pb) => pb.getProductId() === p.getId()).length > 0) {
        const partial = [];
        partial.push(p.getName()?.getDefault());
        for (const b of benefits) {
          if (productBenefits.filter((pb) => pb.getBenefitId() === b.getId()).length > 0) {
            const arr =
              productBenefits.filter((pb: Productbenefit) => {
                return pb.getBenefitId() === b.getId() && p.getId() === pb.getProductId();
              }) || [];

            if (arr.length > 0) {
              partial.push('✔');
            } else {
              partial.push(' ');
            }
          }
        }
        partial.push(<LinkButton url={p.getCardholderUrl()} />);
        partial.push(
          <ActionButton
            name="Download"
            onClick={async () => {
              const b = await createQrCodesZip(p.getCardholderUrl());
              downloadBlob(b, `${p.getName()?.getDefault()}-${p.getId()}.zip`);
            }}
          />,
        );
        productsData.body.push(partial);
      }
    }

    productsData.head.push('URL cardholder product page'.toLocaleUpperCase());
    productsData.head.push('QR CODE');

    return { productsData };
  }, [issuer]);

  return <Table heading={{ name: 'Active products' }} data={data.productsData} />;
}
