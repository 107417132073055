import React from 'react';
import Bar from '../common/charts/Bar';
import ReportTitle from './ReportTitle';
import { useProductBenefitsEligible } from '../../utils/report';
import { Legend } from '../common/charts/Legend';
import { getColorForIndex } from '../../utils';
import { useIssuer } from '../../hooks';
import { Benefit } from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { useControlStyles } from './styles';

export const EligibleConsumption = (props: {
  width?: number;
  height?: number;
  resposive?: boolean;
}): JSX.Element => {
  const { pending, data } = useProductBenefitsEligible();
  const { issuer } = useIssuer();
  const classes = useControlStyles();
  if (pending || !issuer) {
    return <div />;
  }

  return (
    <React.Fragment>
      <ReportTitle
        title="Eligible pass consumption"
        helpText={
          'Compares the number of eligible cards from the contract with the actual number of created cards.'
        }
      />
      <div className={classes.empty} />
      <Bar data={data} {...props} />
      <Legend
        items={issuer.getBenefits().map((b: Benefit, i: number) => ({
          v: '',
          l: issuer.getBenefitName(b.getId()),
          c: getColorForIndex(i),
        }))}
      />
    </React.Fragment>
  );
};
