import { makeStyles } from '@material-ui/core';
import { NewCampaign, Section } from './types';
import { CreateCampaignsRequest } from '../../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { LocalizableString } from '../../../proto/yourpass/mc/loyalty/common/v1/localizable_string_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

const useStyles = makeStyles({
  textField: {
    [`& .MuiOutlinedInput-root`]: {
      borderRadius: 25,
    },
    width: 600,
  },
  dateField: {
    [`& .MuiOutlinedInput-root`]: {
      borderRadius: 25,
    },
  },
  errorText: {
    paddingLeft: 15,
    color: '#f00',
  },
  ilustration: {
    paddingLeft: 64,
    width: 320,
  },
  ilustrationTooltip: {
    width: 180,
    paddingLeft: 78,
  },
});

const formToCreateCampaignRequest = (
  values: NewCampaign,
  languages: string[],
  defaultLanguage: string,
): CreateCampaignsRequest => {
  const r = new CreateCampaignsRequest();

  r.setProductbenefitIdsList(values.productbenefits);

  const messages = values.data[Section.message];
  const changeMessages = values.data[Section.changeMessage];
  const contents = values.data[Section.content];
  const c = new LocalizableString();
  const m = new LocalizableString();
  const cm = new LocalizableString();
  languages.forEach((l) => {
    if (messages) {
      if (l === defaultLanguage) {
        m.setDefault(messages[l]);
      } else {
        m.getTranslatedMap().set(l, messages[l]);
      }
    }

    if (changeMessages !== null) {
      if (l === defaultLanguage) {
        cm.setDefault(changeMessages[l]);
      } else {
        cm.getTranslatedMap().set(l, changeMessages[l]);
      }
    }

    if (contents) {
      if (l === defaultLanguage) {
        c.setDefault(contents[l]);
      } else {
        c.getTranslatedMap().set(l, contents[l]);
      }
    }
  });
  const t = new Timestamp();
  t.fromDate(values.date.utc().startOf('minute').toDate());
  r.setSendTime(t);
  if (contents) {
    r.setContent(c);
  }
  if (messages) {
    r.setMessage(m);
  }
  if (changeMessages !== null) {
    r.setChangeMessage(cm);
  }
  return r;
};

export { formToCreateCampaignRequest, useStyles };
