import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ProductbenefitGroupBy } from '../../../utils/report';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
      [`& > .MuiOutlinedInput-root`]: {
        borderRadius: 25,
        [`& > .MuiSelect-root`]: {
          paddingTop: 10.5,
          paddingBottom: 10.5,
        },
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const GroupBySelect = (props: {
  defaultValue: ProductbenefitGroupBy;
  onChange: (g: ProductbenefitGroupBy) => void;
}) => {
  const classes = useStyles();

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    props.onChange(event.target.value as ProductbenefitGroupBy);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="group-by-select-outlined-label">Group by</InputLabel>
      <Select
        labelId="group-by-select-outlined-label"
        id="group-by-select-outlined"
        defaultValue={props.defaultValue}
        onChange={handleChange}
        label="Group by"
        variant="outlined"
        inputProps={{ margin: 'dense' }}
      >
        <MenuItem value={ProductbenefitGroupBy.PRODUCT}>Product</MenuItem>
        <MenuItem value={ProductbenefitGroupBy.BENEFIT}>Template</MenuItem>
        <MenuItem value={ProductbenefitGroupBy.PRODUCTBENEFIT}>Product template</MenuItem>
      </Select>
    </FormControl>
  );
};
