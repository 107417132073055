import { Moment } from 'moment';

export interface CreateCampaignHOCProps {
  onFinish: () => void;
}

export enum Section {
  message = 'message',
  changeMessage = 'changeMessage',
  content = 'content',
}

export interface CreateCampaignProps {
  onCancel: () => void;
}

export interface ContentProps {
  title: string;
  name: string;
  helpText?: JSX.Element | string;
  imgSrc?: string;
}

export interface List {
  [key: string]: string;
}

export type NewCampaign = {
  productbenefits: string[];
  date: Moment;
  data: {
    [Section.content]: { [key: string]: string } | null;
    [Section.message]: { [key: string]: string } | null;
    [Section.changeMessage]: { [key: string]: string } | null;
  };
};
