import React from 'react';
import moment from 'moment';

type TextDateTimeFProps = { date?: Date };

const render = (props: TextDateTimeFProps, ref: React.Ref<HTMLElement>) => {
  return (
    <span ref={ref}>{props.date && moment(props.date).utc().format('YYYY-MM-DD HH:mm [UTC]')}</span>
  );
};

export const TextDateTime = React.forwardRef<HTMLElement, TextDateTimeFProps>(render);
