import React from 'react';
import { helpers } from '../constants';

export interface Props {
  error?: Error;
  componentStack?: string;
  resetErrorBoundary: () => void;
}
function FallbackError({ error }: Props) {
  return (
    <div role="alert">
      <p>Something went wrong!</p>
      {helpers.isDefined(error) ? <pre>{error.message}</pre> : null}
    </div>
  );
}

export default FallbackError;
