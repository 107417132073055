import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Wrapper } from '../../components';
import { ROUTES } from '../../constants';
import Title from '../../components/common/Title';
import {
  Active,
  Created,
  TimeLine,
  OperatingSystem,
  EligibleConsumption,
} from '../../components/report';
import { ProductBenefitsProvider } from '../../utils/report';

const gridItemStyle = { paddingLeft: 50, paddingRight: 50 };

function Reports() {
  return (
    <Wrapper selectedKey={ROUTES.reports.key}>
      <Title title="Reports" dataTestId="reports-id" />
      <ProductBenefitsProvider>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
          style={{ marginBottom: 100 }}
        >
          <Grid style={gridItemStyle} item xs={12} sm={12} md={6} lg={4}>
            <Created />
          </Grid>
          <Grid style={gridItemStyle} item xs={12} sm={12} md={6} lg={4}>
            <Active />
          </Grid>
          <Grid style={gridItemStyle} item xs={12} sm={12} md={6} lg={4}>
            <OperatingSystem />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
          style={{ marginBottom: 100 }}
        >
          <Grid style={gridItemStyle} item xs={12}>
            <EligibleConsumption resposive={false} width={800} height={300} />
          </Grid>
        </Grid>
      </ProductBenefitsProvider>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid style={gridItemStyle} item xs={12} sm={12} md={12} lg={12}>
          <TimeLine />
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Reports;
