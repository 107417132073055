import React, { useMemo, useState } from 'react';
import Wrapper from '../../components/Wrapper';
import Divider from '../../components/common/Divider';
import { ROUTES } from '../../constants';
import Title from '../../components/common/Title';
import Button from '../../components/common/buttons/Button';
import { useCampaigns, useIssuer, useUser } from '../../hooks';
import {
  Campaign,
  CampaignStatus,
} from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { prepareCampaignCols } from '../../components/campaign';
import { TableData } from '../../components/Table';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ActionButtons, Table } from '../../components';
import { useSnackbar } from 'notistack';
import { CampaignDialog } from '../../components/campaign';
import { useHistory } from 'react-router-dom';
import { CampaignsConsumption } from '../../components/campaign/CampaignsConsumption';

function CampaignsCenter() {
  const campaigns = useCampaigns();
  const { issuer } = useIssuer();
  const { client } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);

  const campaignsData: TableData = useMemo(
    () => ({
      head: ['START', 'PRODUCT', 'TEXT', 'MESSAGE', 'STATE'],
      body: campaigns.data.map((c: Campaign) => {
        const buttons = [
          {
            name: 'view',
            onClick: () => {
              setSelectedCampaign(c);
            },
          },
        ];

        if (c.getStatus() === CampaignStatus.CAMPAIGN_STATUS_SCHEDULED) {
          buttons.push({
            name: 'cancel',
            onClick: async () => {
              try {
                await client?.cancelCampaign(c.getId());
                enqueueSnackbar(`Campaign ${c.getId()} canceled`, { variant: 'success' });
                campaigns.refresh();
              } catch (e) {
                console.error(e);
                enqueueSnackbar('Could not cancel campaign', { variant: 'error' });
              }
            },
          });
        }
        if (c.getStatus() === CampaignStatus.CAMPAIGN_STATUS_DONE) {
        }
        if (c.getStatus() === CampaignStatus.CAMPAIGN_STATUS_IN_PROGRESS) {
        }

        return [
          ...prepareCampaignCols(c, issuer),
          <ActionButtons key="buttons" buttons={buttons} />,
        ];
      }),
    }),
    [campaigns, issuer, enqueueSnackbar, client],
  );

  //const areCampaigns = !!campaignsData;
  const history = useHistory();

  const showCreating = () => {
    history.push(ROUTES.campaignsCreate.path);
  };

  return (
    <Wrapper selectedKey={ROUTES.campaignsCenter.key}>
      <Title title="Campaigns Center" dataTestId="campaigns-center-id" />

      <CampaignDialog
        campaign={selectedCampaign}
        onClose={() => {
          setSelectedCampaign(null);
        }}
      />

      <React.Fragment>
        <CampaignsConsumption />
        <Divider />
        <Box marginBottom={2} display="flex" justifyContent="flex-start">
          <Typography variant="h5">New campaign</Typography>
        </Box>
        <Typography variant="body1">
          In this section you can create and plan new campaign. The earliest available time is
          always at least 15 minutes since opening the scheduling tool.
        </Typography>
        <Button name="Create new Campaign" onClick={showCreating} />
        <Divider />
        <Table
          heading={{ name: 'Campaigns overview' }}
          data={campaignsData}
          onReadMore={campaigns.readNext}
          fallback="No campaign has been created yet"
        />
      </React.Fragment>
    </Wrapper>
  );
}

export default CampaignsCenter;
