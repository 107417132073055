import React, { PropsWithChildren } from 'react';
import { useUser } from '../../hooks';
import { useState, useLayoutEffect, useContext } from 'react';
import {
  GetProductbenefitStatsResponse,
  GetPlatformStatsResponse,
  GetProductCampaignStatsResponse,
  GetProductBenefitUpdateStatsResponse,
} from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { useSnackbar } from 'notistack';
export * from './doughnut';
export * from './bar';
export * from './timeline';

export enum ProductbenefitGroupBy {
  PRODUCT = 'PRODUCT',
  BENEFIT = 'BENEFIT',
  PRODUCTBENEFIT = 'PRODUCT-BENEFIT',
}

export interface State<T> {
  pending: boolean;
  data: T | null;
}
export const useProductBenefits = (): State<GetProductbenefitStatsResponse> => {
  const { client } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State<GetProductbenefitStatsResponse>>({
    pending: true,
    data: null,
  });
  useLayoutEffect(() => {
    const read = async () => {
      if (client) {
        try {
          const res = await client.getProductbenefitStats();
          setState(() => ({
            data: res,
            pending: false,
          }));
        } catch (e) {
          enqueueSnackbar('Could not read product benefit data', { variant: 'error' });
          setState((s) => ({
            ...s,
            pending: false,
          }));
        }
      }
    };
    read();
  }, [client, enqueueSnackbar]);

  return state;
};

export const ProductBenefitsContext = React.createContext<State<GetProductbenefitStatsResponse>>({
  pending: false,
  data: null,
});

export const ProductBenefitsProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const ctx = useProductBenefits();
  return <ProductBenefitsContext.Provider value={ctx}>{children}</ProductBenefitsContext.Provider>;
};

export const useProductBenefitsContext = (): State<GetProductbenefitStatsResponse> => {
  return useContext(ProductBenefitsContext);
};

export const usePlatformStats = () => {
  const { client } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State<GetPlatformStatsResponse>>({
    pending: true,
    data: null,
  });
  useLayoutEffect(() => {
    const read = async () => {
      if (client) {
        try {
          const res = await client.getPlatformStats();
          setState(() => ({
            data: res,
            pending: false,
          }));
        } catch (e) {
          enqueueSnackbar('Could not read platform data', { variant: 'error' });
          setState((s) => ({
            ...s,
            pending: false,
          }));
        }
      }
    };
    read();
  }, [client, enqueueSnackbar]);

  return state;
};

export const useProductCampaignStats = () => {
  const { client } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State<GetProductCampaignStatsResponse>>({
    pending: true,
    data: null,
  });
  useLayoutEffect(() => {
    const read = async () => {
      if (client) {
        try {
          const res = await client.getProductCampaignStats();
          setState(() => ({
            data: res,
            pending: false,
          }));
        } catch (e) {
          enqueueSnackbar('Could not read product campaign data', { variant: 'error' });
          setState((s) => ({
            ...s,
            pending: false,
          }));
        }
      }
    };
    read();
  }, [client, enqueueSnackbar]);

  return state;
};

export const useProductBenefitUpdateStats = () => {
  const { client } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State<GetProductBenefitUpdateStatsResponse>>({
    pending: true,
    data: null,
  });
  useLayoutEffect(() => {
    const read = async () => {
      if (client) {
        try {
          const res = await client.getProductBenefitUpdateStats();
          setState(() => ({
            data: res,
            pending: false,
          }));
        } catch (e) {
          enqueueSnackbar('Could not read product campaign data', { variant: 'error' });
          setState((s) => ({
            ...s,
            pending: false,
          }));
        }
      }
    };
    read();
  }, [client, enqueueSnackbar]);

  return state;
};
