import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

export type ErrorTextProps = { error: string };

const useStyles = makeStyles({
  errorText: {
    paddingLeft: 15,
    color: '#f00',
  },
});

export const ErrorText = ({ error }: ErrorTextProps) => {
  const classes = useStyles();
  return (
    <Typography className={classes.errorText} variant="body2">
      {error}
    </Typography>
  );
};
