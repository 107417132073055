import { CampaignStatus as C } from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import React from 'react';

export const CampaignStatus = (props: { status: C }): JSX.Element => {
  switch (props.status) {
    case C.CAMPAIGN_STATUS_SCHEDULED:
      return <span>scheduled</span>;

    case C.CAMPAIGN_STATUS_IN_PROGRESS:
      return <span>in progress</span>;

    case C.CAMPAIGN_STATUS_DONE:
      return <span>done</span>;

    case C.CAMPAIGN_STATUS_CANCELED:
      return <span>canceled</span>;

    case C.CAMPAIGN_STATUS_UNKNOWN:
    default:
      return <span>unknown</span>;
  }
};
