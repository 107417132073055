/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * @fileoverview gRPC-Web generated client stub for yourpass.mc.loyalty.issuer.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v0.0.0
// source: yourpass/mc/loyalty/issuer/v1/issuer_service.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as yourpass_mc_loyalty_issuer_v1_issuer_service_pb from '../../../../../yourpass/mc/loyalty/issuer/v1/issuer_service_pb';


export class IssuerServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetViewer = new grpcWeb.MethodDescriptor(
    '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetViewer',
    grpcWeb.MethodType.UNARY,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetViewerRequest,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetViewerResponse,
    (request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetViewerRequest) => {
      return request.serializeBinary();
    },
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetViewerResponse.deserializeBinary
  );

  getViewer(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetViewerRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetViewerResponse>;

  getViewer(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetViewerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetViewerResponse) => void): grpcWeb.ClientReadableStream<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetViewerResponse>;

  getViewer(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetViewerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetViewerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetViewer',
        request,
        metadata || {},
        this.methodDescriptorGetViewer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetViewer',
    request,
    metadata || {},
    this.methodDescriptorGetViewer);
  }

  methodDescriptorGetTemplateLayout = new grpcWeb.MethodDescriptor(
    '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetTemplateLayout',
    grpcWeb.MethodType.UNARY,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetTemplateLayoutRequest,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetTemplateLayoutResponse,
    (request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetTemplateLayoutRequest) => {
      return request.serializeBinary();
    },
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetTemplateLayoutResponse.deserializeBinary
  );

  getTemplateLayout(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetTemplateLayoutRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetTemplateLayoutResponse>;

  getTemplateLayout(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetTemplateLayoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetTemplateLayoutResponse) => void): grpcWeb.ClientReadableStream<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetTemplateLayoutResponse>;

  getTemplateLayout(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetTemplateLayoutRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetTemplateLayoutResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetTemplateLayout',
        request,
        metadata || {},
        this.methodDescriptorGetTemplateLayout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetTemplateLayout',
    request,
    metadata || {},
    this.methodDescriptorGetTemplateLayout);
  }

  methodDescriptorChangePassword = new grpcWeb.MethodDescriptor(
    '/yourpass.mc.loyalty.issuer.v1.IssuerService/ChangePassword',
    grpcWeb.MethodType.UNARY,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ChangePasswordRequest,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ChangePasswordResponse,
    (request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ChangePasswordRequest) => {
      return request.serializeBinary();
    },
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ChangePasswordResponse.deserializeBinary
  );

  changePassword(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ChangePasswordResponse>;

  changePassword(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ChangePasswordResponse) => void): grpcWeb.ClientReadableStream<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ChangePasswordResponse>;

  changePassword(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ChangePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ChangePasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.mc.loyalty.issuer.v1.IssuerService/ChangePassword',
        request,
        metadata || {},
        this.methodDescriptorChangePassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.mc.loyalty.issuer.v1.IssuerService/ChangePassword',
    request,
    metadata || {},
    this.methodDescriptorChangePassword);
  }

  methodDescriptorCreateCampaigns = new grpcWeb.MethodDescriptor(
    '/yourpass.mc.loyalty.issuer.v1.IssuerService/CreateCampaigns',
    grpcWeb.MethodType.UNARY,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CreateCampaignsRequest,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CreateCampaignsResponse,
    (request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CreateCampaignsRequest) => {
      return request.serializeBinary();
    },
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CreateCampaignsResponse.deserializeBinary
  );

  createCampaigns(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CreateCampaignsRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CreateCampaignsResponse>;

  createCampaigns(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CreateCampaignsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CreateCampaignsResponse) => void): grpcWeb.ClientReadableStream<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CreateCampaignsResponse>;

  createCampaigns(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CreateCampaignsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CreateCampaignsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.mc.loyalty.issuer.v1.IssuerService/CreateCampaigns',
        request,
        metadata || {},
        this.methodDescriptorCreateCampaigns,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.mc.loyalty.issuer.v1.IssuerService/CreateCampaigns',
    request,
    metadata || {},
    this.methodDescriptorCreateCampaigns);
  }

  methodDescriptorListCampaigns = new grpcWeb.MethodDescriptor(
    '/yourpass.mc.loyalty.issuer.v1.IssuerService/ListCampaigns',
    grpcWeb.MethodType.UNARY,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ListCampaignsRequest,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ListCampaignsResponse,
    (request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ListCampaignsRequest) => {
      return request.serializeBinary();
    },
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ListCampaignsResponse.deserializeBinary
  );

  listCampaigns(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ListCampaignsRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ListCampaignsResponse>;

  listCampaigns(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ListCampaignsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ListCampaignsResponse) => void): grpcWeb.ClientReadableStream<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ListCampaignsResponse>;

  listCampaigns(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ListCampaignsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.ListCampaignsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.mc.loyalty.issuer.v1.IssuerService/ListCampaigns',
        request,
        metadata || {},
        this.methodDescriptorListCampaigns,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.mc.loyalty.issuer.v1.IssuerService/ListCampaigns',
    request,
    metadata || {},
    this.methodDescriptorListCampaigns);
  }

  methodDescriptorUpdateCampaign = new grpcWeb.MethodDescriptor(
    '/yourpass.mc.loyalty.issuer.v1.IssuerService/UpdateCampaign',
    grpcWeb.MethodType.UNARY,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.UpdateCampaignRequest,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.UpdateCampaignResponse,
    (request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.UpdateCampaignRequest) => {
      return request.serializeBinary();
    },
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.UpdateCampaignResponse.deserializeBinary
  );

  updateCampaign(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.UpdateCampaignRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.UpdateCampaignResponse>;

  updateCampaign(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.UpdateCampaignRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.UpdateCampaignResponse) => void): grpcWeb.ClientReadableStream<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.UpdateCampaignResponse>;

  updateCampaign(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.UpdateCampaignRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.UpdateCampaignResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.mc.loyalty.issuer.v1.IssuerService/UpdateCampaign',
        request,
        metadata || {},
        this.methodDescriptorUpdateCampaign,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.mc.loyalty.issuer.v1.IssuerService/UpdateCampaign',
    request,
    metadata || {},
    this.methodDescriptorUpdateCampaign);
  }

  methodDescriptorCancelCampaign = new grpcWeb.MethodDescriptor(
    '/yourpass.mc.loyalty.issuer.v1.IssuerService/CancelCampaign',
    grpcWeb.MethodType.UNARY,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CancelCampaignRequest,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CancelCampaignResponse,
    (request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CancelCampaignRequest) => {
      return request.serializeBinary();
    },
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CancelCampaignResponse.deserializeBinary
  );

  cancelCampaign(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CancelCampaignRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CancelCampaignResponse>;

  cancelCampaign(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CancelCampaignRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CancelCampaignResponse) => void): grpcWeb.ClientReadableStream<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CancelCampaignResponse>;

  cancelCampaign(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CancelCampaignRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.CancelCampaignResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.mc.loyalty.issuer.v1.IssuerService/CancelCampaign',
        request,
        metadata || {},
        this.methodDescriptorCancelCampaign,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.mc.loyalty.issuer.v1.IssuerService/CancelCampaign',
    request,
    metadata || {},
    this.methodDescriptorCancelCampaign);
  }

  methodDescriptorGetProductbenefitStats = new grpcWeb.MethodDescriptor(
    '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetProductbenefitStats',
    grpcWeb.MethodType.UNARY,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductbenefitStatsRequest,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductbenefitStatsResponse,
    (request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductbenefitStatsRequest) => {
      return request.serializeBinary();
    },
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductbenefitStatsResponse.deserializeBinary
  );

  getProductbenefitStats(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductbenefitStatsRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductbenefitStatsResponse>;

  getProductbenefitStats(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductbenefitStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductbenefitStatsResponse) => void): grpcWeb.ClientReadableStream<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductbenefitStatsResponse>;

  getProductbenefitStats(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductbenefitStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductbenefitStatsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetProductbenefitStats',
        request,
        metadata || {},
        this.methodDescriptorGetProductbenefitStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetProductbenefitStats',
    request,
    metadata || {},
    this.methodDescriptorGetProductbenefitStats);
  }

  methodDescriptorGetProductCampaignStats = new grpcWeb.MethodDescriptor(
    '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetProductCampaignStats',
    grpcWeb.MethodType.UNARY,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductCampaignStatsRequest,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductCampaignStatsResponse,
    (request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductCampaignStatsRequest) => {
      return request.serializeBinary();
    },
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductCampaignStatsResponse.deserializeBinary
  );

  getProductCampaignStats(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductCampaignStatsRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductCampaignStatsResponse>;

  getProductCampaignStats(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductCampaignStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductCampaignStatsResponse) => void): grpcWeb.ClientReadableStream<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductCampaignStatsResponse>;

  getProductCampaignStats(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductCampaignStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductCampaignStatsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetProductCampaignStats',
        request,
        metadata || {},
        this.methodDescriptorGetProductCampaignStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetProductCampaignStats',
    request,
    metadata || {},
    this.methodDescriptorGetProductCampaignStats);
  }

  methodDescriptorGetProductBenefitUpdateStats = new grpcWeb.MethodDescriptor(
    '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetProductBenefitUpdateStats',
    grpcWeb.MethodType.UNARY,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductBenefitUpdateStatsRequest,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductBenefitUpdateStatsResponse,
    (request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductBenefitUpdateStatsRequest) => {
      return request.serializeBinary();
    },
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductBenefitUpdateStatsResponse.deserializeBinary
  );

  getProductBenefitUpdateStats(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductBenefitUpdateStatsRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductBenefitUpdateStatsResponse>;

  getProductBenefitUpdateStats(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductBenefitUpdateStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductBenefitUpdateStatsResponse) => void): grpcWeb.ClientReadableStream<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductBenefitUpdateStatsResponse>;

  getProductBenefitUpdateStats(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductBenefitUpdateStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetProductBenefitUpdateStatsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetProductBenefitUpdateStats',
        request,
        metadata || {},
        this.methodDescriptorGetProductBenefitUpdateStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetProductBenefitUpdateStats',
    request,
    metadata || {},
    this.methodDescriptorGetProductBenefitUpdateStats);
  }

  methodDescriptorGetPlatformStats = new grpcWeb.MethodDescriptor(
    '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetPlatformStats',
    grpcWeb.MethodType.UNARY,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetPlatformStatsRequest,
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetPlatformStatsResponse,
    (request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetPlatformStatsRequest) => {
      return request.serializeBinary();
    },
    yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetPlatformStatsResponse.deserializeBinary
  );

  getPlatformStats(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetPlatformStatsRequest,
    metadata: grpcWeb.Metadata | null): Promise<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetPlatformStatsResponse>;

  getPlatformStats(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetPlatformStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetPlatformStatsResponse) => void): grpcWeb.ClientReadableStream<yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetPlatformStatsResponse>;

  getPlatformStats(
    request: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetPlatformStatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: yourpass_mc_loyalty_issuer_v1_issuer_service_pb.GetPlatformStatsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetPlatformStats',
        request,
        metadata || {},
        this.methodDescriptorGetPlatformStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/yourpass.mc.loyalty.issuer.v1.IssuerService/GetPlatformStats',
    request,
    metadata || {},
    this.methodDescriptorGetPlatformStats);
  }

}

