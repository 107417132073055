import React, { useMemo } from 'react';
import { Table } from '../../../components';
import { useIssuer } from '../../../hooks';
import {
  Productbenefit,
  OriginMethod,
} from '../../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { TableData } from '../../../components/Table';

export function SpecialProductTable() {
  const { issuer } = useIssuer();

  const data = useMemo(() => {
    const productsData: TableData = {
      head: ['', 'PRODUCT', 'PRICING  MODEL', 'PASS CREATION MODEL'],
      body: [],
    };

    const productBenefits = (issuer.getProductbenefitsList() || []).filter(
      (pb: Productbenefit) => pb.getOriginMethod() != OriginMethod.ORIGIN_METHOD_ISSUE,
    );

    for (const pb of productBenefits) {
      const partial = [];
      partial.push(issuer.getBenefitName(pb.getBenefitId()));
      partial.push(issuer.getProductName(pb.getProductId()));

      // TODO get this information from data
      partial.push(issuer.getProductName(pb.getProductId()) === 'CUSTOM' ? 'Custom *' : 'Eligible');
      partial.push(<OriginMethodLabel method={pb.getOriginMethod()} />);
      productsData.body.push(partial);
    }

    return { productsData };
  }, [issuer]);

  return <Table heading={{ name: 'Special products' }} data={data.productsData} />;
}

const OriginMethodLabel = ({ method }: { method: OriginMethod }) => {
  switch (method) {
    case OriginMethod.ORIGIN_METHOD_ISSUE:
      return <span>issue</span>;

    case OriginMethod.ORIGIN_METHOD_BULK_IMPORT:
      return <span>Custom issued</span>;

    case OriginMethod.ORIGIN_METHOD_CUSTOMER_INTEGRATION:
      return <span>Custom developed</span>;

    case OriginMethod.ORIGIN_METHOD_UNKNOWN:
    default:
      return <span>Unknown</span>;
  }
};
