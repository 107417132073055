import { GetViewerResponse } from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';

export default class Viewer {
  private displayName: string;
  private email: string;

  constructor(d: GetViewerResponse | null) {
    this.displayName = d?.getDisplayName() || '';
    this.email = d?.getEmail() || '';
  }

  public getDisplayName() {
    return this.displayName;
  }

  public getEmail() {
    return this.email;
  }
}
