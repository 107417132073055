import React, { CSSProperties } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import CopyButton from './CopyButton';

type ActionButtonProps = { style?: CSSProperties; url: string };

const useStyles = makeStyles(() =>
  createStyles({
    linkButton: {
      cursor: 'pointer',
      fontSize: 12,
      color: '#000',
      textDecoration: 'underline',
      fontFamily:
        'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;',
    },
    linkContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
  }),
);

export const LinkButton = ({ url, style }: ActionButtonProps) => {
  const classes = useStyles();
  const handleClick = () => {
    window.open(url, '_blank');
  };
  return (
    <span className={classes.linkContainer}>
      <Link style={style} component="button" onClick={handleClick} className={classes.linkButton}>
        {url}
      </Link>
      <CopyButton text={url} />
    </span>
  );
};
