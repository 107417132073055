import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dashboard from '@material-ui/icons/Dashboard';
import Create from '@material-ui/icons/Create';
import Layers from '@material-ui/icons/Layers';
import PieChart from '@material-ui/icons/PieChart';
import pjson from '../../package.json';

import { ROUTES } from '../constants';
import { toolBar } from './Header';

interface Props {
  selectedKey?: number;
}

const drawerWidth = 240;

const menuItems = [
  { key: 0, name: 'Dashboard', path: ROUTES.dashboard.path, ItemIcon: Dashboard },
  { key: 1, name: 'Campaigns Center', path: ROUTES.campaignsCenter.path, ItemIcon: Create },
  { key: 2, name: 'Product Overview', path: ROUTES.productOverview.path, ItemIcon: Layers },
  { key: 3, name: 'Reports', path: ROUTES.reports.path, ItemIcon: PieChart },
  // {
  //   key: 4,
  //   name: 'Marketing Materials',
  //   path: ROUTES.marketingMaterials.path,
  //   ItemIcon: Visibility,
  // },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      color: '#fff', // ⬜,
      marginTop: 30,
      backgroundColor: theme.palette.primary.light,
    },
    drawerContainer: {
      overflow: 'auto',
      margin: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      paddingBottom: theme.spacing(2),
      justifyContent: 'space-between',
    },
    toolBar: {
      height: toolBar.height,
    },
    item: {
      fontWeight: 'bold',
    },
    selected: {
      color: theme.palette.secondary.main,
      minWidth: 'auto',
      backgroundColor: 'inherit !important',
    },
    unselected: {
      color: '#fff', // ⬜,
      minWidth: 'auto',
    },
    icon: {
      marginRight: 15,
    },
    version: {
      //textAlign: 'end'
    },
    link: {
      color: '#fff',
      textDecoration: 'underline',
    },
  }),
);

function Menu({ selectedKey }: Props) {
  const classes = useStyles();
  const history = useHistory();

  function goToPage(path: string) {
    history.push(path);
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar className={classes.toolBar} />
      <div className={classes.drawerContainer}>
        <List>
          {menuItems.map(({ key, name, ItemIcon, path }) => {
            const isSelected = key === selectedKey;
            return (
              <ListItem
                selected={isSelected}
                button
                key={key}
                onClick={() => goToPage(path)}
                classes={{ selected: classes.selected }}
              >
                <ListItemIcon
                  classes={{ root: isSelected ? classes.selected : classes.unselected }}
                >
                  <ItemIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText primary={name} classes={{ primary: classes.item }} />
              </ListItem>
            );
          })}
        </List>
        <p className={classes.version}>
          &copy;&nbsp;
          <a
            href="https://yourpass.eu"
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            yourpass.eu
          </a>
          &nbsp;|&nbsp;v{pjson.version}
        </p>
      </div>
    </Drawer>
  );
}

export default Menu;
