import React, { MouseEvent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import MaterialButton from '@material-ui/core/Button';
import { helpers } from '../../../constants';

interface Props {
  color?: 'primary' | 'secondary';
  type?: 'submit' | 'button';
  onClick?: (e: MouseEvent) => void;
  name: string;
  disabled?: boolean;
}

const buttonWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'none',
      fontWeight: 'bold',
      color: '#fff',
      borderRadius: 25,
      width: 300,
      margin: theme.spacing(3, 0, 2),
    },
  }),
);

const Button: React.FC<Props> = ({
  color = 'secondary',
  type = 'button',
  onClick = helpers.noop,
  name,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <MaterialButton
      type={type}
      variant="contained"
      color={color}
      fullWidth
      disabled={disabled}
      size="large"
      className={classes.button}
      onClick={onClick}
    >
      {name}
    </MaterialButton>
  );
};

export { buttonWidth };
export default Button;
