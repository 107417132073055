import React, { MouseEvent, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';

import { useUser, useIssuer } from '../hooks';
import { ROUTES, helpers } from '../constants';
import logo from '../assets/logo.png';

interface Props {
  children?: React.ReactNode;
  onClick?: (e: MouseEvent) => void;
}

export const toolBar = {
  height: 70,
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      height: toolBar.height,
    },
    toolBar: {
      height: toolBar.height,
    },
    title: {
      flexGrow: 1,
      textTransform: 'uppercase',
    },
    actionButtons: {
      width: 'inherit',
    },
    button: {
      paddingTop: 0,
      paddingRight: 12,
      paddingBottom: 0,
      paddingLeft: 12,
    },
    divider: {
      backgroundColor: '#fff', // ⬜
    },
  });
});

const Header: FunctionComponent<Props> = ({ children, onClick = helpers.noop }) => {
  const classes = useStyles();

  return (
    <AppBar color="primary" position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Button onClick={onClick} aria-label="mastercard-logo">
          <img src={logo} width={70} alt="mastercard-logo" />
        </Button>
        <Box component="span" marginRight={5}>
          <Typography variant="h6">mastercard</Typography>
        </Box>
        <Typography variant="h5" className={classes.title}>
          <Box component="span" fontWeight="fontWeightBold">
            Loyalty admin portal
          </Box>
        </Typography>
        {children}
      </Toolbar>
    </AppBar>
  );
};

export const HeaderClassic: React.FC = () => {
  const { signOut } = useUser();
  const { issuer } = useIssuer();
  const history = useHistory();
  const classes = useStyles();

  function goToAccount() {
    history.push(ROUTES.account.path);
  }

  function goToDashboard() {
    history.push(ROUTES.dashboard.path);
  }

  // function goToIssuerSettings() {
  //  history.push(ROUTES.issuerSettings.path);
  // }

  return (
    <Header onClick={goToDashboard}>
      <Grid container justify="flex-end" className={classes.actionButtons}>
        <Button
          className={classes.button}
          startIcon={<AccountBalanceWalletOutlinedIcon />}
          /* onClick={goToIssuerSettings} */
          color="inherit"
          style={{ cursor: 'default' }}
        >
          {issuer.getIssuer()?.getName()?.getDefault()}
        </Button>
        <Button
          className={classes.button}
          startIcon={<PersonOutlineRoundedIcon />}
          onClick={goToAccount}
          color="inherit"
        >
          My account
        </Button>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Button
          className={classes.button}
          startIcon={<ExitToAppRoundedIcon />}
          onClick={signOut}
          color="inherit"
        >
          Logout
        </Button>
      </Grid>
    </Header>
  );
};

export default Header;
