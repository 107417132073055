/* eslint-disable react/display-name */

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Item from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import { helpers } from '../../constants';
import GetApp from '@material-ui/icons/GetApp';
import Cancel from '@material-ui/icons/Cancel';

export type FontSize = 'inherit' | 'default' | 'small' | 'large';
type OnClick = (id?: string) => void;
type Bonus = { id?: string; color?: 'primary' | 'secondary' | 'inherit' | 'default' };

type Item = { name: string; onClick?: OnClick };
type Items = Array<Item>;

export type Icon = ({ fontSize }: { fontSize?: FontSize }) => JSX.Element;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButtons: {
      display: 'inline-flex',
      width: 'fit-content',
      '& hr': {
        margin: theme.spacing(0, 0.5),
      },
    },
    links: {
      color: '#000',
    },
    iconButton: {
      textTransform: 'none',
      width: 200,
      borderRadius: 20,
    },
    button: {
      textTransform: 'none',
      width: 150,
      borderRadius: 20,
    },
    icon: {
      fontSize: 20,
      margin: 1,
    },
  }),
);

export const prepareButton = (items: Items, bonus?: Bonus) => () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {items.map((button) => (
        <>
          <Button
            onClick={() => (helpers.isDefined(button.onClick) ? button.onClick() : helpers.noop)}
            type="button"
            variant="contained"
            color={bonus?.color || 'primary'}
            size="large"
            className={classes.button}
          >
            {button.name}
          </Button>
          <Divider orientation="vertical" flexItem />
        </>
      ))}
    </React.Fragment>
  );
};

export const prepareUploadButton = (items: Items, bonus?: Bonus) => () => {
  let onClick = helpers.noop;
  const button = items[0];
  const classes = useStyles();
  if (button.onClick) {
    onClick = button.onClick;
  }

  return (
    <Button
      variant="outlined"
      startIcon={<GetApp />}
      onClick={onClick}
      type="button"
      color={bonus?.color || 'primary'}
      size="large"
      className={classes.iconButton}
    >
      {button.name}
    </Button>
  );
};

export const prepareCancelButton = (items: Items, bonus?: Bonus) => () => {
  let onClick = helpers.noop;
  const button = items[0];
  const classes = useStyles();
  if (button.onClick) {
    onClick = button.onClick;
  }

  return (
    <Button
      variant="outlined"
      startIcon={<Cancel />}
      onClick={onClick}
      type="button"
      color={bonus?.color || 'primary'}
      size="large"
      className={classes.iconButton}
    >
      {button.name}
    </Button>
  );
};

export const prepareIcon = (Icon: Icon, fontSize: FontSize) => () => {
  const classes = useStyles();

  return (
    <Box className={classes.icon}>
      <Icon fontSize={fontSize} />
    </Box>
  );
};

export const prepareImage = (image: string, alt?: string) => (): JSX.Element => {
  return <img src={image} width={70} alt={alt} />;
};
