import { getColorForIndex } from '../colors';
import { useEffect, useState } from 'react';
import { ProductbenefitGroupBy, useProductBenefitsContext } from './index';
import { ProductbenefitStats } from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { useIssuer } from '../../hooks';

type DounuthDataSet = {
  labels: string[];
  datasets: [
    {
      data: number[];
      backgroundColor: string[];
      hoverBackgroundColor: string[];
    },
  ];
};

enum ProductBenefitsDoughnutType {
  active,
  created,
}

export const useProductBenefitsDoughnut = (
  groupBy: ProductbenefitGroupBy,
  type: ProductBenefitsDoughnutType,
) => {
  const { pending, data } = useProductBenefitsContext();
  const { issuer } = useIssuer();
  const [state, setState] = useState<DounuthDataSet>({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
      },
    ],
  });

  useEffect(() => {
    const preparedData: DounuthDataSet = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
        },
      ],
    };

    if (data && !pending) {
      const d: { [key: string]: number } = {};

      data.getProductbenefitsList().forEach((v: ProductbenefitStats) => {
        let index = v.getId();
        switch (groupBy) {
          case ProductbenefitGroupBy.PRODUCT: {
            const pb = issuer.getProductbenefit(v.getId());
            index = pb.getProductId();
            break;
          }
          case ProductbenefitGroupBy.BENEFIT: {
            const pb = issuer.getProductbenefit(v.getId());
            index = pb.getBenefitId();
            break;
          }
        }

        const value =
          type === ProductBenefitsDoughnutType.created
            ? v.getCreatedList()[0]
            : v.getRegistrationsActiveList()[0];
        if (!d[index]) {
          d[index] = 0;
        }
        d[index] = d[index] + value;
      });

      Object.keys(d).forEach((id: string, i: number) => {
        const c = getColorForIndex(i);

        switch (groupBy) {
          case ProductbenefitGroupBy.PRODUCT: {
            preparedData.labels.push(issuer.getProductName(id));
            break;
          }
          case ProductbenefitGroupBy.BENEFIT: {
            preparedData.labels.push(issuer.getBenefitName(id));
            break;
          }
          default: {
            preparedData.labels.push(issuer.getProductbenefitName(id));
            break;
          }
        }
        preparedData.datasets[0].data.push(d[id]);
        preparedData.datasets[0].backgroundColor.push(c);
        preparedData.datasets[0].hoverBackgroundColor.push(c);
      });
    }

    setState(preparedData);
  }, [data, groupBy, pending, issuer, type]);

  return { pending, data: state };
};

export const useProductBenefitsActive = (groupBy: ProductbenefitGroupBy) => {
  return useProductBenefitsDoughnut(groupBy, ProductBenefitsDoughnutType.active);
};

export const useProductBenefitsCreated = (groupBy: ProductbenefitGroupBy) => {
  return useProductBenefitsDoughnut(groupBy, ProductBenefitsDoughnutType.created);
};
