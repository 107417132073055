import React, { useState } from 'react';
import ReportTitle from './ReportTitle';
import Doughnut from '../common/charts/Doughnut';
import { GroupBySelect } from '../common/selects/GroupBySelect';
import { ProductbenefitGroupBy, useProductBenefitsActive } from '../../utils/report';
import { useControlStyles } from './styles';

export const Active = (): JSX.Element => {
  const [groupBy, setGroupBy] = useState(ProductbenefitGroupBy.PRODUCT);
  const { pending, data } = useProductBenefitsActive(groupBy);
  const classes = useControlStyles();

  if (pending) {
    return <div />;
  }
  return (
    <React.Fragment>
      <ReportTitle
        title="Active Passes"
        helpText={
          'Pass is "activated" when a device (mobile phone) registers it in a mobile wallet application. Each pass can be also unregistered if deleted from a mobile wallet application. This report shows all registrations minus all unregistrations.'
        }
      />
      <div className={classes.root}>
        <GroupBySelect defaultValue={groupBy} onChange={setGroupBy} />
      </div>
      <Doughnut data={data} />
    </React.Fragment>
  );
};
