import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';

import { HeaderClassic, toolBar } from './Header';
import Menu from './Menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolBar: {
      height: toolBar.height,
    },
  }),
);

const Wrapper: FunctionComponent<{ selectedKey?: number }> = ({ selectedKey, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HeaderClassic />
      <Menu selectedKey={selectedKey} />
      <main className={classes.content}>
        <Toolbar className={classes.toolBar} />
        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
          {children}
        </Grid>
      </main>
    </div>
  );
};

export default Wrapper;
