export const pallete = [
  '#3e072f',
  '#760733',
  '#c80135',
  '#f4643b',
  '#ffc400',
  '#bdbb00',
  '#006c2f',
  '#104b64',
  '#a8dadc',
  '#dcdcdc',
];

export const getColorForIndex = (index: number): string => {
  if (index < pallete.length) {
    return pallete[index];
  }

  const i = index % pallete.length;
  const it = Math.round(index / pallete.length);

  const c = pallete[i];

  return colorLuminance(c, it / 100);
};

function colorLuminance(hex: string, lum: number) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
}
