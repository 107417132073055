import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { getColorForIndex } from '../../../utils';
import { ProductCampaignStats } from '../../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import ReportTitle from '../../report/ReportTitle';
import { useProductCampaignStats } from '../../../utils/report';
import { useIssuer } from '../../../hooks';
import { CircleCounter } from '../../common';

const useCampaignStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  }),
);

export const CampaignsEligibleConsumption = (): JSX.Element => {
  const { issuer } = useIssuer();

  const classes = useCampaignStyles();

  const { pending, data } = useProductCampaignStats();

  if (pending || !issuer) {
    return <div />;
  }

  return (
    <div>
      <ReportTitle
        title="Campaigns consumption"
        helpText={
          'This section displays the consumed and total available number of content updates and notifications available for each card.  The consumed figure includes both scheduled and previously sent content updates / notifications.  It is possible to send both the content update and notification within one overall update.'
        }
      />
      <div className={classes.container}>
        {data?.getProductsList().map((p: ProductCampaignStats, index: number) => {
          p.getConsumed();
          return (
            <CircleCounter
              key={p.getId()}
              color={getColorForIndex(index)}
              title={issuer.getProductName(p.getId())}
              current={p.getConsumed()}
              total={p.getLimit()}
            />
          );
        })}
      </div>
    </div>
  );
};
