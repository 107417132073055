import React from 'react';
import Grid from '@material-ui/core/Grid';
import TransferList, { Targets } from './ProductBenefitTransferList';
import Button from '../../common/buttons/Button';
import Divider from '../../common/Divider';
import Heading from './Heading';
import Content from './Content';
import { CreateCampaignHOCProps, CreateCampaignProps, NewCampaign, Section } from './types';
import { useIssuer, useUser } from '../../../hooks';
import { DateTimePicker } from '../../common/DateTimePicker';
import moment, { Moment } from 'moment';
import { Formik, useFormikContext, FormikHelpers, getIn } from 'formik';
import { formToCreateCampaignRequest } from './helpers';
import { useSnackbar } from 'notistack';
import { prepareValidationSchema } from './validation';
import { ErrorMessage } from './ErrorMessage';
import contentImg from '../../../assets/content.png';
import messageImg from '../../../assets/message.png';

function CreateCampaign({ onCancel }: CreateCampaignProps) {
  const { issuer } = useIssuer();
  const [productBenefit, setProductbenefits] = React.useState<Targets>({
    left: issuer
      .getProductbenefitsList()
      .filter((p) => p.getActive())
      .map((p) => p.getId()),
    right: [],
  });

  const {
    values,
    setFieldValue,
    submitForm,
    isSubmitting,
    errors,
    isValid,
    isValidating,
    submitCount,
  } = useFormikContext<NewCampaign>();

  const handleSetList = (t: Targets) => {
    setProductbenefits(t);
    setFieldValue('productbenefits', t.right);
  };
  return (
    <div>
      <Heading
        title="Choose Target Audience"
        helpText="Select a product or benefit you want to send a notification to and/or update the content of. If you want to send new content/notification to all benefits related a certain product, you have to add them to the selection one by one."
      />
      <TransferList list={productBenefit} setList={handleSetList} />
      <ErrorMessage path={'productbenefits'} />
      <Divider />

      <Content
        imgSrc={messageImg}
        title="Message"
        helpText={
          <div>
            <p>
              Submit the content of the message to be displayed as a push notification on the
              cardholder’s phone. Only plain text and emojis are supported (e.g.{' '}
              <span role="img" aria-label="smile">
                😀
              </span>{' '}
              <span role="img" aria-label="smile">
                😀
              </span>{' '}
              <span role="img" aria-label="smile">
                😀
              </span>
              ). Using URL addresses isn`&apos;t allowed.
            </p>
          </div>
        }
        name={`data.${Section.message}`}
      />

      {issuer.hasEditableChangeMessage() && (
        <>
          <Divider />
          <Content
            title="Change message"
            helpText={
              <div>
                <p>
                  Override change message shown in the push notification. The message must include
                  %@ for the change message to be shown.
                </p>
              </div>
            }
            name={`data.${Section.changeMessage}`}
          />
        </>
      )}

      <Divider />

      <Content
        title="Content"
        helpText={
          <div>
            <p>
              Submit the content of the issuer enabled text field to be displayed on the back side
              of the card. The content can be a combination of plain text, emojis and clickable
              links. Clickable links have to be formatted the following way:{' '}
              {'<a href="https://…”URL LINK”">interactive text referencing the URL address</a>'}.
              The only supported formatting method is adding line breaks by pressing ENTER.
            </p>
            Example:
            <ul>
              <li>
                first sentence{' '}
                <span role="img" aria-label="smile">
                  😀
                </span>
              </li>
              <li>
                second sentence - for more info{' '}
                {'<a href=”https://www.google.com/”URL LINK”  ”>CLICK HERE</a>'}
              </li>
              <li>third sentence</li>
            </ul>
          </div>
        }
        name={`data.${Section.content}`}
        imgSrc={contentImg}
      />

      <Divider />
      <Heading
        title="Start of campaign"
        helpText={
          'Schedule a time when the push notification or new content will be sent out. The earliest available time is always at least 15 minutes since submiting the campaign form. The system will start sending out notifications or content updates at the selected time. This process can take 1-15 min depending on the number of passes.'
        }
      />

      <p>
        The earliest available time is always at least 15 minutes since submiting the campaign form.
      </p>
      <DateTimePicker
        error={getIn(errors, 'date', false) && submitCount > 0}
        value={values.date}
        minDate={new Date()}
        minDateMessage={'Date should not be before minimal date'}
        onChange={(date: Moment | null) => {
          setFieldValue('date', date);
        }}
        disablePast={true}
        disableFuture={false}
      />
      {getIn(errors, 'date', false) && submitCount > 0 && <ErrorMessage path={'date'} />}

      <Divider />
      <Grid container direction="row" justify="space-around" alignItems="flex-start">
        <Button disabled={isSubmitting} color="primary" name="Cancel" onClick={onCancel} />
        <Button
          name="Create new Campaign"
          onClick={() => {
            submitForm();
          }}
          disabled={isSubmitting || (submitCount > 0 && !isValid) || isValidating}
        />
      </Grid>
    </div>
  );
}

function CreateCampaignHOC({ onFinish }: CreateCampaignHOCProps) {
  const initialValues: NewCampaign = {
    productbenefits: [],
    data: { message: null, changeMessage: null, content: null },
    date: moment().add(20, 'minutes').utc(),
  };
  const { client } = useUser();
  const { issuer } = useIssuer();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: NewCampaign, actions: FormikHelpers<NewCampaign>) => {
    if (client) {
      const vals: NewCampaign = {
        ...values,
        data: {
          ...values.data,
          changeMessage: issuer.hasEditableChangeMessage() ? values.data.changeMessage : null,
        },
      };

      try {
        actions.setSubmitting(true);
        const r = formToCreateCampaignRequest(
          vals,
          issuer.getLanguages(),
          issuer.getDefualtLanguage(),
        );
        await client.createCampaign(r);
        enqueueSnackbar('Campaign created', { variant: 'success' });
        onFinish();
      } catch (e) {
        console.error(e);
        enqueueSnackbar('Could not create campaign', { variant: 'error' });
      }
      actions.setSubmitting(false);
    }
  };

  const onCancel = () => {
    onFinish();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={prepareValidationSchema(issuer.getLanguages())}
      validateOnChange={true}
    >
      <CreateCampaign onCancel={onCancel} />
    </Formik>
  );
}

export { CreateCampaignHOC };
export default CreateCampaign;
