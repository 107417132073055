/* eslint-disable react/display-name */
import React from 'react';
import Wrapper from '../../components/Wrapper';
import Title from '../../components/common/Title';
import { Table, ChangePasswordDialog, ActionButton } from '../../components';
import Divider from '../../components/common/Divider';
import { useAccountData, passwordHeading, profileHeading } from './data';

function Account() {
  const { profileData } = useAccountData();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const passwordData = {
    head: [],
    body: [
      [
        'Password',
        '**************',
        <ActionButton
          key="edit"
          name={'edit'}
          onClick={() => {
            handleClickOpen();
          }}
        />,
      ],
    ],
  };

  return (
    <Wrapper>
      <Title title="Account Settings" dataTestId="acount-id" />
      <Table heading={profileHeading} data={profileData} lastRight percentageWidth />
      <Divider width={700} />
      <Table heading={passwordHeading} data={passwordData} lastRight percentageWidth />
      <ChangePasswordDialog open={open} onClose={handleClose} />
    </Wrapper>
  );
}

export default Account;
