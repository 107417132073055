import React from 'react';
import Wrapper from '../../components/Wrapper';
import { Table } from '../../components';
import { ROUTES } from '../../constants';
import Title from '../../components/common/Title';
import { useCampaigns, useIssuer, useUser } from '../../hooks';
import {
  Campaign,
  CampaignStatus as CS,
} from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { TableData } from '../../components/Table';
import { prepareCampaignCols } from '../../components/campaign';
import { Link, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Active, EligibleConsumption } from '../../components/report';
import { ProductBenefitsProvider } from '../../utils/report';
import Divider from '../../components/common/Divider';
import { CampaignsConsumption } from '../../components/campaign/CampaignsConsumption';

const gridItemStyle = { marginBottom: 50 };

function Dashboard() {
  const campaigns = useCampaigns();
  const history = useHistory();
  const { issuer } = useIssuer();
  const { viewer } = useUser();

  const scheduleData: TableData = {
    head: ['START', 'PRODUCT', 'TEXT', 'MESSAGE', 'STATUS'],
    body: campaigns.data
      .filter((c: Campaign) => c.getStatus() !== CS.CAMPAIGN_STATUS_DONE)
      .slice(0, 3)
      .map((c: Campaign) => [...prepareCampaignCols(c, issuer)]),
  };

  return (
    <Wrapper selectedKey={ROUTES.dashboard.key}>
      <Title
        title={`Welcome, ${
          viewer?.getDisplayName() || ''
        }, in Mastercard - Yourpass ADMIN PORTAL for issuers.`}
        dataTestId="dashboard-id"
      />
      <ProductBenefitsProvider>
        <CampaignsConsumption />

        <Divider />
        <Table
          heading={{ name: 'Upcoming campaigns' }}
          data={scheduleData}
          pending={campaigns.pending}
          fallback={
            <div>
              No scheduled campaigns. You can create new camaign in{' '}
              <Link to={ROUTES.campaignsCenter.path}> campaign center</Link>.
            </div>
          }
          onReadMore={() => {
            history.push(ROUTES.campaignsCenter.path);
          }}
          more="Go to campaign center"
        />
        <Divider />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
          style={{ marginBottom: 50 }}
        >
          <Grid style={gridItemStyle} item xs={12} sm={12} md={12} lg={8} xl={6}>
            <EligibleConsumption resposive={false} width={600} height={300} />
          </Grid>
          <Grid style={gridItemStyle} item xs={12} sm={12} md={12} lg={4} xl={6}>
            <Active />
          </Grid>
        </Grid>
      </ProductBenefitsProvider>
    </Wrapper>
  );
}

export default Dashboard;
