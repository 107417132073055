import { useUser } from './';
import { useState, useLayoutEffect } from 'react';
import { Campaign } from '../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { useSnackbar } from 'notistack';

interface State {
  pending: boolean;
  data: Campaign[];
  nextPageToken: string;
  hasMore: boolean;
  refreshCount: number;
  pageToken: string;
}

export const useCampaigns = (): {
  pending: boolean;
  data: Campaign[];
  readNext?: () => void;
  refresh: () => void;
} => {
  const { client } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    pending: true,
    data: [],
    nextPageToken: '',
    pageToken: '',
    hasMore: true,
    refreshCount: 0,
  });

  useLayoutEffect(() => {
    const read = async () => {
      if (client) {
        try {
          const res = await client.getCampaigns(state.pageToken);
          setState((s) => ({
            data: [...s.data, ...res.getCampaignsList()],
            pending: false,
            nextPageToken: res.getNextPageToken(),
            hasMore: res.getNextPageToken() !== '',
            refreshCount: s.refreshCount,
            pageToken: s.pageToken,
          }));
        } catch (e) {
          enqueueSnackbar('Could not read camaigns data', { variant: 'error' });
          setState((s) => ({
            ...s,
            pending: false,
          }));
        }
      }
    };

    read();
  }, [state.pageToken, client, enqueueSnackbar, state.refreshCount]);

  return {
    pending: state.pending,
    data: state.data,
    readNext: state.hasMore
      ? () => {
          setState((s) => ({
            ...s,
            pageToken: s.nextPageToken,
          }));
        }
      : undefined,
    refresh: () => {
      setState((s) => ({
        data: [],
        pending: false,
        nextPageToken: '',
        pageToken: '',
        hasMore: true,
        refreshCount: s.refreshCount + 1,
      }));
    },
  };
};
