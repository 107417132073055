import React from 'react';
import { Button } from '../common';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Campaign } from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { useIssuer } from '../../hooks';
import { useStyles } from '../spec/createCampaign/helpers';
import { TextField, Grid, Typography } from '@material-ui/core';
import { CampaignStartDate } from './CampaignStartDate';

export const CampaignDialog = (props: { campaign: null | Campaign; onClose: () => void }) => {
  const { issuer } = useIssuer();
  const classes = useStyles();

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="simple-dialog-title"
      open={!!props.campaign}
      fullWidth={true}
      maxWidth={'lg'}
      scroll={'paper'}
    >
      <DialogTitle id="simple-dialog-title">Campaign detail</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Typography variant="h6" gutterBottom>
            ID
          </Typography>
          <Typography variant="body1" gutterBottom>
            {props.campaign?.getId()}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Target
          </Typography>
          <Typography variant="body1" gutterBottom>
            {props.campaign && issuer.getProductbenefitName(props.campaign?.getProductbenefitId())}
          </Typography>
          <Typography variant="h6" gutterBottom>
            Start time
          </Typography>
          <Typography variant="body1" gutterBottom>
            {props.campaign && <CampaignStartDate campaign={props.campaign} />}
          </Typography>

          {props.campaign?.hasMessage() && (
            <>
              <Typography variant="h6" gutterBottom>
                Messages
              </Typography>
              {issuer.getLanguages().map((l: string, i: number) => (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  key={i}
                  value={
                    l === issuer.getDefualtLanguage()
                      ? props.campaign?.getMessage()?.getDefault()
                      : props.campaign?.getMessage()?.getTranslatedMap().get(l)
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="medium"
                  name={l}
                  type="text"
                  label={l.toUpperCase()}
                  multiline
                  rows={3}
                  rowsMax={10}
                  className={classes.textField}
                />
              ))}
            </>
          )}

          {props.campaign?.hasChangeMessage() && issuer.hasEditableChangeMessage() && (
            <>
              <Typography variant="h6" gutterBottom>
                Change Messages
              </Typography>
              {issuer.getLanguages().map((l: string, i: number) => (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  key={i}
                  value={
                    l === issuer.getDefualtLanguage()
                      ? props.campaign?.getChangeMessage()?.getDefault()
                      : props.campaign?.getChangeMessage()?.getTranslatedMap().get(l)
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="medium"
                  name={l}
                  type="text"
                  label={l.toUpperCase()}
                  multiline
                  rows={3}
                  rowsMax={10}
                  className={classes.textField}
                />
              ))}
            </>
          )}

          {props.campaign?.hasContent() && (
            <>
              <Typography variant="h6" gutterBottom>
                Content
              </Typography>
              {issuer.getLanguages().map((l: string, i: number) => (
                <TextField
                  key={i}
                  value={
                    l === issuer.getDefualtLanguage()
                      ? props.campaign?.getContent()?.getDefault()
                      : props.campaign?.getContent()?.getTranslatedMap().get(l)
                  }
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  size="medium"
                  name={l}
                  type="text"
                  label={l.toUpperCase()}
                  multiline
                  rows={3}
                  rowsMax={10}
                  className={classes.textField}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ))}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={props.onClose} color="primary" type="button" name="close" />
      </DialogActions>
    </Dialog>
  );
};
