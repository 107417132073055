import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Granularity } from '../../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
      [`& > .MuiOutlinedInput-root`]: {
        borderRadius: 25,
        [`& > .MuiSelect-root`]: {
          paddingTop: 10.5,
          paddingBottom: 10.5,
        },
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const GranularitySelect = (props: {
  value: Granularity;
  onChange: (g: Granularity) => void;
}) => {
  const classes = useStyles();

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    props.onChange(event.target.value as Granularity);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="granularity-select-outlined-label">Breakdown</InputLabel>
      <Select
        labelId="granularity-select-outlined-label"
        id="granularity-select-outlined"
        value={props.value}
        onChange={handleChange}
        label="Breakdown"
        variant="outlined"
        inputProps={{ margin: 'dense' }}
      >
        <MenuItem value={Granularity.GRANULARITY_DAILY}>Daily</MenuItem>
        <MenuItem value={Granularity.GRANULARITY_WEEKLY}>Weekly</MenuItem>
        <MenuItem value={Granularity.GRANULARITY_MONTHLY}>Monthly</MenuItem>
      </Select>
    </FormControl>
  );
};
