import React, { PropsWithChildren } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    labeledContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    childContainer: {},
    border: {
      height: '2em',
      width: '100%',
      borderBottom: '3px solid lightgrey',
      borderRight: '3px solid lightgrey',
      borderLeft: '3px solid lightgrey',
      marginBottom: 10,
    },
    label: {
      fontSize: 14,
      textTransform: 'uppercase',
      fontWeight: 'bolder',
    },
  }),
);

export const BottomLabeled = (props: PropsWithChildren<{ label: string }>) => {
  const classes = useStyles();
  return (
    <div className={classes.labeledContainer}>
      <div className={classes.childContainer}>{props.children}</div>
      <div className={classes.border} />
      <label className={classes.label}>{props.label}</label>
    </div>
  );
};
