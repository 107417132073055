import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

interface Props {
  title: string;
  dataTestId?: string;
}

const useStyles = makeStyles({
  heading: {
    fontWeight: 'bold',
  },
});

function Title({ title, dataTestId }: Props) {
  const classes = useStyles();
  return (
    <Box marginY={2}>
      <Typography variant="h4" gutterBottom data-testid={dataTestId} className={classes.heading}>
        {title}
      </Typography>
    </Box>
  );
}

export default Title;
