import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import MaterialButton from '@material-ui/core/Button';

interface Props {
  text: string;
  icon: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    copyButton: {
      cursor: 'pointer',
      textTransform: 'none',
      fontWeight: 'bold',
      color: '#000',
      borderRadius: 25,
      margin: theme.spacing(0, 1, 0),
    },
  }),
);

const IconButton: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  return (
    <MaterialButton
      type="button"
      variant="contained"
      size="small"
      className={classes.copyButton}
      startIcon={props.icon}
    >
      {props.text}
    </MaterialButton>
  );
};

export default IconButton;
