import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Toggle from '../../common/Toggle';
import { HelpText } from '../..';

interface Props {
  title: string;
  onToggleChange?: (e: boolean) => void;
  enabled?: boolean;
  light?: boolean;
  helpText?: JSX.Element | string;
}

function Heading({ title, onToggleChange, enabled, light, helpText }: Props) {
  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Box marginY={2}>
        {!helpText && (
          <Typography variant="h4" gutterBottom style={light ? { opacity: 0.5 } : {}}>
            {title}
          </Typography>
        )}
        {helpText && (
          <Typography variant="h4" gutterBottom style={light ? { opacity: 0.5 } : {}}>
            {title}
            {helpText && <HelpText content={helpText} />}
          </Typography>
        )}
      </Box>
      {onToggleChange && enabled !== undefined && (
        <Toggle checked={enabled} onChange={onToggleChange} />
      )}
    </Grid>
  );
}

export default Heading;
