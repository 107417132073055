import React from 'react';
import { DateTimePicker as MDateTimePicker } from '@material-ui/pickers';
import { Moment } from 'moment';
import CalendarIcon from '@material-ui/icons/Event';
import { InputAdornment, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dateField: {
    minWidth: 220,
    marginBottom: 8,
    [`& .MuiOutlinedInput-root`]: {
      borderRadius: 25,
    },
  },
});

export type DateTimePickerProps = {
  error?: boolean;
  value: Date | Moment | undefined | null;
  onChange: (date: Moment | null, value?: string | null) => void;
  minDate?: Moment | undefined | Date;
  minDateMessage?: string | JSX.Element;
  disableFuture?: boolean;
  disablePast?: boolean;
  dense?: boolean;
  variant?: 'dialog' | 'inline' | 'static';
};

export const DateTimePicker = (props: DateTimePickerProps) => {
  const classes = useStyles();
  const { dense, ...rest } = props;
  return (
    <MDateTimePicker
      {...rest}
      inputVariant="outlined"
      autoOk
      ampm={false}
      value={props.value}
      onChange={props.onChange}
      minutesStep={5}
      format={'YYYY-MM-DD HH:mm [UTC]'}
      className={classes.dateField}
      InputProps={{
        margin: dense ? 'dense' : undefined,
        startAdornment: (
          <InputAdornment position="start">
            <CalendarIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
