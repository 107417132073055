import React, { PropsWithChildren } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    idLabel: {
      cursor: 'pointer',
      fontSize: 12,
      color: '#000',
      textDecoration: 'underline',
      fontFamily:
        'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;',
      whiteSpace: 'nowrap',
    },
  }),
);

export const IdLabel = (props: PropsWithChildren<unknown>) => {
  const classes = useStyles();
  return <span className={classes.idLabel}>{props.children}</span>;
};
