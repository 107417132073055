import { useUser, useIssuer } from '../../hooks';
import { getColorForIndex } from '../colors';
import { useState, useEffect } from 'react';
import { useProductBenefitsContext } from './index';
import {
  ProductbenefitStats,
  Product,
  Benefit,
} from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';

type BarDataSet = {
  data: number[];
  backgroundColor: string;
  label: string;
};

type BarDataSets = {
  labels: string[];
  datasets: BarDataSet[];
};

export const useProductBenefitsEligible = () => {
  const { pending, data } = useProductBenefitsContext();
  const { viewer } = useUser();
  const { issuer } = useIssuer();
  const [state, setState] = useState<BarDataSets>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const preparedData: BarDataSets = {
      labels: [],
      datasets: [],
    };
    if (data && !pending) {
      issuer.getProductsList().forEach((p: Product) => {
        preparedData.labels.push(issuer.getProductName(p.getId()));
      });

      issuer.getBenefits().forEach((b: Benefit, ib) => {
        const dataset: BarDataSet = {
          //stack: b.getId(),
          label: issuer.getBenefitName(b.getId()),
          data: [],
          backgroundColor: getColorForIndex(ib),
        };

        issuer.getProductsList().forEach((p: Product) => {
          const filtered = data.getProductbenefitsList().filter((v: ProductbenefitStats) => {
            const pb = issuer.getProductbenefit(v.getId());
            return pb.getProductId() === p.getId() && b.getId() === pb.getBenefitId();
          });
          let value = 0;
          if (filtered.length > 0) {
            value = filtered[0].getCreatedList()[0];
          }
          dataset.data.push(value);
        });

        preparedData.datasets.push(dataset);
      });
    }
    setState(preparedData);
  }, [pending, issuer, viewer, data]);

  return { pending, data: state };
};
