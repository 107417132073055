const login = { path: '/login' };
// MENU ITEMS ⬇
const dashboard = { path: '/', key: 0 };
const campaignsCenter = { path: '/campaigns', key: 1 };

const productOverview = { path: '/productOverview', key: 2 };
const reports = { path: '/reports', key: 3 };
const marketingMaterials = { path: '/marketingMaterials', key: 4 };
// MENU ITEMS ⬆
const account = { path: '/account' };
const issuerSettings = { path: '/issuer/settings' };
const campaignsCreate = { path: '/campaigns/create' };

const ROUTES = {
  login,
  dashboard,
  campaignsCenter,
  campaignsCreate,
  productOverview,
  reports,
  marketingMaterials,
  account,
  issuerSettings,
};

export default ROUTES;
