import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { ROUTES } from './constants';
import { PrivateRoute } from './routes';
import { FourOhFour } from './screens';
import Dashboard from './screens/dashboard';
import CampaignsCenter from './screens/campaignsCenter';
import CampaignsCreate from './screens/campaignsCenter/create';
import ProductOverview from './screens/productOverview';
import Account from './screens/account';
import MarketingMaterials from './screens/marketingMaterials';
import Reports from './screens/reports';
import IssuerSettings from './screens/issuerSettings';

// const LazyLogin = React.lazy(() => import('../screens/Login'));
// const LazyDashboard = React.lazy(() => import('../components/screens/dashboard'));
// const LazyMessageCenter = React.lazy(() => import('../components/screens/messageCenter'));
// const LazyProductOverview = React.lazy(() => import('../components/screens/productOverview'));
// const LazyReports = React.lazy(() => import('../screens/Reports'));
// const LazyMarketingMaterials = React.lazy(() => import('../components/screens/marketingMaterials'));
// const LazyAccount = React.lazy(() => import('../components/screens/account'));
// const Lazy404 = React.lazy(() => import('../screens/404'));

function App() {
  return (
    <BrowserRouter>
      <Switch>
        {/* MENU ITEMS ⬇ */}
        <PrivateRoute exact path={ROUTES.dashboard.path} component={Dashboard} />
        <PrivateRoute exact path={ROUTES.campaignsCenter.path} component={CampaignsCenter} />
        <PrivateRoute exact path={ROUTES.campaignsCreate.path} component={CampaignsCreate} />
        <PrivateRoute exact path={ROUTES.productOverview.path} component={ProductOverview} />
        <PrivateRoute exact path={ROUTES.reports.path} component={Reports} />
        <PrivateRoute exact path={ROUTES.marketingMaterials.path} component={MarketingMaterials} />

        {/* MENU ITEMS ⬆ */}
        <PrivateRoute exact path={ROUTES.account.path} component={Account} />
        <PrivateRoute exact path={ROUTES.issuerSettings.path} component={IssuerSettings} />
        <Route component={FourOhFour} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
