import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import MaterialButton from '@material-ui/core/Button';
import copy from 'clipboard-copy';
import Tooltip from '@material-ui/core/Tooltip';

interface Props {
  text: string;
}

const buttonWidth = 30;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    copyButton: {
      textTransform: 'none',
      fontWeight: 'bold',
      color: '#000',
      borderRadius: 20,
      margin: theme.spacing(0, 1, 0),
    },
  }),
);

const CopyButton: React.FC<Props> = ({ text }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleOnTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      open={open}
      title={'Copied to clipboard!'}
      leaveDelay={1500}
      onClose={handleOnTooltipClose}
      arrow={true}
    >
      <MaterialButton
        type="button"
        variant="contained"
        size="small"
        className={classes.copyButton}
        onClick={async () => {
          await copy(text);
          setOpen(true);
        }}
      >
        Copy
      </MaterialButton>
    </Tooltip>
  );
};

export { buttonWidth };
export default CopyButton;
