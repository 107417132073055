import React, { PropsWithChildren } from 'react';
import Issuer from '../utils/api/Issuer';

type Context = {
  issuer: Issuer;
};

export const IssuerContext = React.createContext<Context>({
  issuer: new Issuer(null),
});

export const IssuerProvider: React.FC<PropsWithChildren<Context>> = ({ children, issuer }) => {
  return <IssuerContext.Provider value={{ issuer }}>{children}</IssuerContext.Provider>;
};
