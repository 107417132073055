import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import HelpText from '../HelpText';

const ReportTitle = (props: { title: string; helpText: JSX.Element | string }): JSX.Element => {
  return (
    <Box marginBottom={2} display="flex" justifyContent="flex-start" flexBasis="min-content">
      <Typography variant="h5">
        {props.title}
        <HelpText content={props.helpText} />
      </Typography>
    </Box>
  );
};

export default ReportTitle;
