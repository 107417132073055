/* eslint-disable @typescript-eslint/no-empty-function */
function noop() {}

function isDefined<T>(x: T | undefined): x is T {
  return !(typeof x === 'undefined' || x === null);
}

function isFunction<T>(x: T): boolean {
  return typeof x === 'function';
}

export default { isFunction, isDefined, noop };
