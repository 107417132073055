import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Heading from './Heading';
import { useStyles } from './helpers';
import { ContentProps, NewCampaign } from './types';
import { ErrorMessage } from './ErrorMessage';
import { useFormikContext, getIn } from 'formik';
import { useIssuer } from '../../../hooks';
import { Hidden } from '@material-ui/core';

function Content({ title, name, helpText, imgSrc }: ContentProps) {
  const classes = useStyles();
  const { values, setFieldValue, errors, submitCount } = useFormikContext<NewCampaign>();
  const { issuer } = useIssuer();

  const onToggleChange = (checked: boolean) => {
    setFieldValue(name, checked ? {} : null);
  };

  const changeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${name}.${e.currentTarget.name}`, e.currentTarget.value);
  };

  const enabled = getIn(values, name, null);
  return (
    <Grid container>
      <Grid item>
        <Grid container direction="column">
          <Heading
            light={!enabled}
            enabled={enabled}
            title={title}
            onToggleChange={onToggleChange}
            helpText={
              <div>
                {helpText}
                {imgSrc && (
                  <Hidden lgUp={true}>
                    <img src={imgSrc} className={classes.ilustrationTooltip} alt={title} />
                  </Hidden>
                )}
              </div>
            }
          />
          {issuer.getLanguages().map((l: string, i: number) => (
            <React.Fragment key={i}>
              <TextField
                error={getIn(errors, `${name}.${l}`, false) && submitCount > 0}
                onChange={changeTextField}
                disabled={!enabled}
                variant="outlined"
                margin="normal"
                fullWidth
                size="medium"
                name={l}
                type="text"
                id={`${name}-${l}`}
                label={l.toUpperCase()}
                multiline
                rows={3}
                rowsMax={10}
                style={!enabled ? { opacity: 0.5 } : {}}
                className={classes.textField}
              />
              <ErrorMessage path={`${name}.${l}`} />
            </React.Fragment>
          ))}
          <ErrorMessage path={name} />
        </Grid>
        <ErrorMessage path={'data'} />
      </Grid>
      {imgSrc && (
        <Hidden mdDown={true}>
          <Grid item>
            <img
              src={imgSrc}
              className={classes.ilustration}
              alt={title}
              style={!enabled ? { opacity: 0.25, filter: 'grayscale(100%)' } : {}}
            />
          </Grid>
        </Hidden>
      )}
    </Grid>
  );
}

export default Content;
