import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

type CircleCounterProps = { total: number; current: number; title: string; color: string };

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    circle: {
      margin: 10,
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 100,
      height: 100,
      fontSize: 28,
      borderRadius: 50,
      border: '4px solid',
    },
  }),
);

export const CircleCounter = ({
  total,
  current,
  color,
  title,
}: CircleCounterProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.circle} style={{ borderColor: color }}>
        {current}/{total}
      </div>
      {title}
    </div>
  );
};
