import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core';
import MuiDivider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      height: 1,
      maxWidth: 1000,
      margin: theme.spacing(5, 0),
    },
  }),
);

const Divider = ({ width }: { width?: number }) => {
  const classes = useStyles();
  return <MuiDivider style={{ width: width || 'auto' }} className={classes.divider} flexItem />;
};

export default Divider;
