import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider, CssBaseline } from '@material-ui/core';

import './styles/index.css';
import * as serviceWorker from './serviceWorker';
import { FallbackError } from './components';
import App from './App';
import { theme } from './utils';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import './components/common/charts/chartjs';

moment.updateLocale('utc', { week: { dow: 1, doy: 4 } });

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
        <ErrorBoundary FallbackComponent={FallbackError}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <ThemeProvider theme={theme}>
              <CssBaseline /> {/* Different browsers => different default styling*/}
              <App />
            </ThemeProvider>
          </React.Suspense>
        </ErrorBoundary>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
