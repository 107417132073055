import moment, { Moment } from 'moment';
import * as chartjs from 'chart.js';
import { ProductbenefitGroupBy, State } from './index';
import {
  GetProductbenefitStatsResponse,
  Granularity,
  ProductbenefitStats,
} from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { useUser, useIssuer } from '../../hooks';
import { useSnackbar } from 'notistack';
import { useState, useLayoutEffect } from 'react';
import Issuer from '../api/Issuer';
import { getColorForIndex } from '../colors';

const timelineLabel = (t: Timestamp, granularity: Granularity) => {
  const m = moment(t.toDate());
  switch (granularity) {
    case Granularity.GRANULARITY_DAILY:
      return m.format('YYYY-MM-DD');
    case Granularity.GRANULARITY_WEEKLY:
      const start = m.clone().startOf('week').format('YYYY-MM-DD');
      const end = m.clone().endOf('week').format('YYYY-MM-DD');
      return `${start} - ${end} (${m.format('[w]W')})`;
    case Granularity.GRANULARITY_MONTHLY:
      return m.format('MMM YYYY');
    default:
      return m.format('YYYY-MM-DD');
  }
};

const toCreateIncrementTimeline = (
  data: GetProductbenefitStatsResponse,
  from: Moment,
  to: Moment,
  granularity: Granularity,
  groupBy: ProductbenefitGroupBy,
  issuer: Issuer,
): chartjs.ChartData => {
  const labels = data.getXList().map((t) => timelineLabel(t, granularity));

  const timeLine: chartjs.ChartData = {
    labels: labels,
    datasets: [],
  };

  const d: { [key: string]: number[] } = {};

  data.getProductbenefitsList().forEach((pb: ProductbenefitStats) => {
    let key = pb.getId();
    switch (groupBy) {
      case ProductbenefitGroupBy.PRODUCT: {
        key = issuer.getProductbenefit(pb.getId()).getProductId();
        break;
      }
      case ProductbenefitGroupBy.BENEFIT: {
        key = issuer.getProductbenefit(pb.getId()).getBenefitId();
        break;
      }
      case ProductbenefitGroupBy.PRODUCTBENEFIT: {
        key = pb.getId();
        break;
      }
      default: {
        key = pb.getId();
      }
    }

    if (!d[key]) {
      d[key] = labels.map(() => 0);
    }

    pb.getCreatedList().forEach((v: number, i: number) => {
      d[key][i] = d[key][i] + v;
    });
  });

  Object.keys(d).forEach((key) => {
    d[key] = d[key].map((v, index) => {
      if (index > 0) {
        return v - d[key][index - 1];
      }
      return 0;
    });
  });

  Object.keys(d).forEach((key, index) => {
    let label;
    switch (groupBy) {
      case ProductbenefitGroupBy.PRODUCT: {
        label = issuer.getProductName(key);
        break;
      }
      case ProductbenefitGroupBy.BENEFIT: {
        label = issuer.getBenefitName(key);
        break;
      }
      case ProductbenefitGroupBy.PRODUCTBENEFIT: {
        label = issuer.getProductbenefitName(key);
        break;
      }
      default: {
        label = key;
        break;
      }
    }

    const dataset: chartjs.ChartDataSets = {
      label,
      data: d[key],
      backgroundColor: getColorForIndex(index),
    };
    timeLine.datasets?.push(dataset);
  });

  // Be carefull, timeline data has added one interval more => we need remove previous interval
  timeLine.labels = timeLine.labels?.slice(1);
  timeLine.datasets = timeLine.datasets?.map((d: chartjs.ChartDataSets) => ({
    ...d,
    data: d.data?.slice(1),
  }));

  return timeLine;
};

export const useCreationIncrementTimeline = (
  from: Moment,
  to: Moment,
  granularity: Granularity,
  groupBy: ProductbenefitGroupBy,
): State<chartjs.ChartData> => {
  const { client } = useUser();
  const { issuer } = useIssuer();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State<GetProductbenefitStatsResponse>>({
    pending: true,
    data: null,
  });
  useLayoutEffect(() => {
    const read = async () => {
      if (client) {
        try {
          // Be carefull, timeline add offset to from date by granularity  => we need read previous interval to calculate increment
          let fromWithOffset = moment(from);
          // prepare offset
          if (granularity === Granularity.GRANULARITY_DAILY) {
            fromWithOffset = fromWithOffset.add(-1, 'day');
          }
          if (granularity === Granularity.GRANULARITY_WEEKLY) {
            fromWithOffset = fromWithOffset.add(-1, 'week');
          }
          if (granularity === Granularity.GRANULARITY_MONTHLY) {
            fromWithOffset = fromWithOffset.add(-1, 'month');
          }

          const res = await client.getProductbenefitStats({
            from: fromWithOffset.toDate(),
            to: to.toDate(),
            granularity,
          });
          setState(() => ({
            data: res,
            pending: false,
          }));
        } catch (e) {
          enqueueSnackbar('Could not read create increments timeline data', { variant: 'error' });
          setState((s) => ({
            ...s,
            pending: false,
          }));
        }
      }
    };
    read();
  }, [client, enqueueSnackbar, from, to, granularity, groupBy]);

  return {
    data: state.data
      ? toCreateIncrementTimeline(state.data, from, to, granularity, groupBy, issuer)
      : null,
    pending: state.pending,
  };
};
