// source: yourpass/mc/loyalty/issuer/v1/issuer_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var yourpass_mc_loyalty_common_v1_localizable_string_pb = require('../../../../../yourpass/mc/loyalty/common/v1/localizable_string_pb.js');
goog.object.extend(proto, yourpass_mc_loyalty_common_v1_localizable_string_pb);
var yourpass_mc_loyalty_common_v1_pk_images_pb = require('../../../../../yourpass/mc/loyalty/common/v1/pk_images_pb.js');
goog.object.extend(proto, yourpass_mc_loyalty_common_v1_pk_images_pb);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.Benefit', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.Campaign', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.CampaignStatus', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.Granularity', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.Issuer', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.OriginMethod', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.Pricing', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.Product', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.Productbenefit', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest', null, global);
goog.exportSymbol('proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.repeatedFields_, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yourpass.mc.loyalty.issuer.v1.Issuer.repeatedFields_, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.Issuer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.Issuer.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.Issuer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.Product.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.Benefit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.Benefit.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.Benefit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.Productbenefit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.Productbenefit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.repeatedFields_, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.repeatedFields_, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.Campaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.Campaign.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.Campaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.repeatedFields_, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.displayName = 'proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest;
  return proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.repeatedFields_ = [4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    issuer: (f = msg.getIssuer()) && proto.yourpass.mc.loyalty.issuer.v1.Issuer.toObject(includeInstance, f),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.yourpass.mc.loyalty.issuer.v1.Product.toObject, includeInstance),
    benefitsList: jspb.Message.toObjectList(msg.getBenefitsList(),
    proto.yourpass.mc.loyalty.issuer.v1.Benefit.toObject, includeInstance),
    productbenefitsList: jspb.Message.toObjectList(msg.getProductbenefitsList(),
    proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.toObject, includeInstance),
    downloadsList: jspb.Message.toObjectList(msg.getDownloadsList(),
    proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse;
  return proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new proto.yourpass.mc.loyalty.issuer.v1.Issuer;
      reader.readMessage(value,proto.yourpass.mc.loyalty.issuer.v1.Issuer.deserializeBinaryFromReader);
      msg.setIssuer(value);
      break;
    case 4:
      var value = new proto.yourpass.mc.loyalty.issuer.v1.Product;
      reader.readMessage(value,proto.yourpass.mc.loyalty.issuer.v1.Product.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 5:
      var value = new proto.yourpass.mc.loyalty.issuer.v1.Benefit;
      reader.readMessage(value,proto.yourpass.mc.loyalty.issuer.v1.Benefit.deserializeBinaryFromReader);
      msg.addBenefits(value);
      break;
    case 6:
      var value = new proto.yourpass.mc.loyalty.issuer.v1.Productbenefit;
      reader.readMessage(value,proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.deserializeBinaryFromReader);
      msg.addProductbenefits(value);
      break;
    case 7:
      var value = new proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile;
      reader.readMessage(value,proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.deserializeBinaryFromReader);
      msg.addDownloads(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIssuer();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.yourpass.mc.loyalty.issuer.v1.Issuer.serializeBinaryToWriter
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.yourpass.mc.loyalty.issuer.v1.Product.serializeBinaryToWriter
    );
  }
  f = message.getBenefitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.yourpass.mc.loyalty.issuer.v1.Benefit.serializeBinaryToWriter
    );
  }
  f = message.getProductbenefitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.serializeBinaryToWriter
    );
  }
  f = message.getDownloadsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.serializeBinaryToWriter
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Issuer issuer = 3;
 * @return {?proto.yourpass.mc.loyalty.issuer.v1.Issuer}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.getIssuer = function() {
  return /** @type{?proto.yourpass.mc.loyalty.issuer.v1.Issuer} */ (
    jspb.Message.getWrapperField(this, proto.yourpass.mc.loyalty.issuer.v1.Issuer, 3));
};


/**
 * @param {?proto.yourpass.mc.loyalty.issuer.v1.Issuer|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.setIssuer = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.clearIssuer = function() {
  return this.setIssuer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.hasIssuer = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Product products = 4;
 * @return {!Array<!proto.yourpass.mc.loyalty.issuer.v1.Product>}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.yourpass.mc.loyalty.issuer.v1.Product>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yourpass.mc.loyalty.issuer.v1.Product, 4));
};


/**
 * @param {!Array<!proto.yourpass.mc.loyalty.issuer.v1.Product>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Product=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Product}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.yourpass.mc.loyalty.issuer.v1.Product, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * repeated Benefit benefits = 5;
 * @return {!Array<!proto.yourpass.mc.loyalty.issuer.v1.Benefit>}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.getBenefitsList = function() {
  return /** @type{!Array<!proto.yourpass.mc.loyalty.issuer.v1.Benefit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yourpass.mc.loyalty.issuer.v1.Benefit, 5));
};


/**
 * @param {!Array<!proto.yourpass.mc.loyalty.issuer.v1.Benefit>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.setBenefitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Benefit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Benefit}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.addBenefits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.yourpass.mc.loyalty.issuer.v1.Benefit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.clearBenefitsList = function() {
  return this.setBenefitsList([]);
};


/**
 * repeated Productbenefit productbenefits = 6;
 * @return {!Array<!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit>}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.getProductbenefitsList = function() {
  return /** @type{!Array<!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yourpass.mc.loyalty.issuer.v1.Productbenefit, 6));
};


/**
 * @param {!Array<!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.setProductbenefitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.addProductbenefits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.yourpass.mc.loyalty.issuer.v1.Productbenefit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.clearProductbenefitsList = function() {
  return this.setProductbenefitsList([]);
};


/**
 * repeated DownloadableFile downloads = 7;
 * @return {!Array<!proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile>}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.getDownloadsList = function() {
  return /** @type{!Array<!proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile, 7));
};


/**
 * @param {!Array<!proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.setDownloadsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.addDownloads = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetViewerResponse.prototype.clearDownloadsList = function() {
  return this.setDownloadsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest;
  return proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string template_id = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutRequest.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    templateLayout: (f = msg.getTemplateLayout()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    images: (f = msg.getImages()) && yourpass_mc_loyalty_common_v1_pk_images_pb.PkImages.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse;
  return proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setTemplateLayout(value);
      break;
    case 3:
      var value = new yourpass_mc_loyalty_common_v1_pk_images_pb.PkImages;
      reader.readMessage(value,yourpass_mc_loyalty_common_v1_pk_images_pb.PkImages.deserializeBinaryFromReader);
      msg.setImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplateLayout();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getImages();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      yourpass_mc_loyalty_common_v1_pk_images_pb.PkImages.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Struct template_layout = 2;
 * @return {?proto.google.protobuf.Struct}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.prototype.getTemplateLayout = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 2));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.prototype.setTemplateLayout = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.prototype.clearTemplateLayout = function() {
  return this.setTemplateLayout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.prototype.hasTemplateLayout = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional yourpass.mc.loyalty.common.v1.PkImages images = 3;
 * @return {?proto.yourpass.mc.loyalty.common.v1.PkImages}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.prototype.getImages = function() {
  return /** @type{?proto.yourpass.mc.loyalty.common.v1.PkImages} */ (
    jspb.Message.getWrapperField(this, yourpass_mc_loyalty_common_v1_pk_images_pb.PkImages, 3));
};


/**
 * @param {?proto.yourpass.mc.loyalty.common.v1.PkImages|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.prototype.setImages = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.prototype.clearImages = function() {
  return this.setImages(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetTemplateLayoutResponse.prototype.hasImages = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.Issuer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Issuer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.toObject(includeInstance, f),
    entity: (f = msg.getEntity()) && yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.toObject(includeInstance, f),
    languagesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    editableChangeMessage: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Issuer}
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.Issuer;
  return proto.yourpass.mc.loyalty.issuer.v1.Issuer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Issuer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Issuer}
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString;
      reader.readMessage(value,yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString;
      reader.readMessage(value,yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.deserializeBinaryFromReader);
      msg.setEntity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addLanguages(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditableChangeMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.Issuer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Issuer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.serializeBinaryToWriter
    );
  }
  f = message.getEntity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.serializeBinaryToWriter
    );
  }
  f = message.getLanguagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getEditableChangeMessage();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Issuer} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional yourpass.mc.loyalty.common.v1.LocalizableString name = 2;
 * @return {?proto.yourpass.mc.loyalty.common.v1.LocalizableString}
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.getName = function() {
  return /** @type{?proto.yourpass.mc.loyalty.common.v1.LocalizableString} */ (
    jspb.Message.getWrapperField(this, yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString, 2));
};


/**
 * @param {?proto.yourpass.mc.loyalty.common.v1.LocalizableString|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Issuer} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Issuer} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional yourpass.mc.loyalty.common.v1.LocalizableString entity = 3;
 * @return {?proto.yourpass.mc.loyalty.common.v1.LocalizableString}
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.getEntity = function() {
  return /** @type{?proto.yourpass.mc.loyalty.common.v1.LocalizableString} */ (
    jspb.Message.getWrapperField(this, yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString, 3));
};


/**
 * @param {?proto.yourpass.mc.loyalty.common.v1.LocalizableString|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Issuer} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.setEntity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Issuer} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.clearEntity = function() {
  return this.setEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.hasEntity = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string languages = 4;
 * @return {!Array<string>}
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.getLanguagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Issuer} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.setLanguagesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Issuer} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.addLanguages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Issuer} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.clearLanguagesList = function() {
  return this.setLanguagesList([]);
};


/**
 * optional bool editable_change_message = 5;
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.getEditableChangeMessage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Issuer} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Issuer.prototype.setEditableChangeMessage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.toObject(includeInstance, f),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    cardholderUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    eligibleCards: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Product}
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.Product;
  return proto.yourpass.mc.loyalty.issuer.v1.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Product}
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString;
      reader.readMessage(value,yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCardholderUrl(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEligibleCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.serializeBinaryToWriter
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCardholderUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEligibleCards();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Product} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional yourpass.mc.loyalty.common.v1.LocalizableString name = 2;
 * @return {?proto.yourpass.mc.loyalty.common.v1.LocalizableString}
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.getName = function() {
  return /** @type{?proto.yourpass.mc.loyalty.common.v1.LocalizableString} */ (
    jspb.Message.getWrapperField(this, yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString, 2));
};


/**
 * @param {?proto.yourpass.mc.loyalty.common.v1.LocalizableString|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Product} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Product} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool active = 3;
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Product} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string cardholder_url = 4;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.getCardholderUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Product} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.setCardholderUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 eligible_cards = 5;
 * @return {number}
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.getEligibleCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Product} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Product.prototype.setEligibleCards = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.Benefit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Benefit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.toObject(includeInstance, f),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Benefit}
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.Benefit;
  return proto.yourpass.mc.loyalty.issuer.v1.Benefit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Benefit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Benefit}
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString;
      reader.readMessage(value,yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.Benefit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Benefit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.serializeBinaryToWriter
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Benefit} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional yourpass.mc.loyalty.common.v1.LocalizableString name = 2;
 * @return {?proto.yourpass.mc.loyalty.common.v1.LocalizableString}
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.prototype.getName = function() {
  return /** @type{?proto.yourpass.mc.loyalty.common.v1.LocalizableString} */ (
    jspb.Message.getWrapperField(this, yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString, 2));
};


/**
 * @param {?proto.yourpass.mc.loyalty.common.v1.LocalizableString|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Benefit} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.Benefit.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Benefit} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool active = 3;
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Benefit} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Benefit.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    benefitId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    templateId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    issueUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    originMethod: jspb.Message.getFieldWithDefault(msg, 9, 0),
    pricing: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit}
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.Productbenefit;
  return proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit}
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBenefitId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueUrl(value);
      break;
    case 9:
      var value = /** @type {!proto.yourpass.mc.loyalty.issuer.v1.OriginMethod} */ (reader.readEnum());
      msg.setOriginMethod(value);
      break;
    case 10:
      var value = /** @type {!proto.yourpass.mc.loyalty.issuer.v1.Pricing} */ (reader.readEnum());
      msg.setPricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBenefitId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIssueUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOriginMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getPricing();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string benefit_id = 3;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.getBenefitId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.setBenefitId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool active = 6;
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string template_id = 7;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string issue_url = 8;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.getIssueUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.setIssueUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional OriginMethod origin_method = 9;
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.OriginMethod}
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.getOriginMethod = function() {
  return /** @type {!proto.yourpass.mc.loyalty.issuer.v1.OriginMethod} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.OriginMethod} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.setOriginMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional Pricing pricing = 10;
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Pricing}
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.getPricing = function() {
  return /** @type {!proto.yourpass.mc.loyalty.issuer.v1.Pricing} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Pricing} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Productbenefit} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Productbenefit.prototype.setPricing = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentPassword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newPassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest;
  return proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string current_password = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.prototype.getCurrentPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.prototype.setCurrentPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_password = 2;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordRequest.prototype.setNewPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse;
  return proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ChangePasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    downloadUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile}
 */
proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile;
  return proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile}
 */
proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string download_url = 3;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.prototype.getDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.DownloadableFile.prototype.setDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendTime: (f = msg.getSendTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    productbenefitIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    message: (f = msg.getMessage()) && yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.toObject(includeInstance, f),
    content: (f = msg.getContent()) && yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.toObject(includeInstance, f),
    changeMessage: (f = msg.getChangeMessage()) && yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest;
  return proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSendTime(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductbenefitIds(value);
      break;
    case 3:
      var value = new yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString;
      reader.readMessage(value,yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 4:
      var value = new yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString;
      reader.readMessage(value,yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 5:
      var value = new yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString;
      reader.readMessage(value,yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.deserializeBinaryFromReader);
      msg.setChangeMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProductbenefitIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.serializeBinaryToWriter
    );
  }
  f = message.getChangeMessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp send_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.getSendTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.setSendTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.clearSendTime = function() {
  return this.setSendTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.hasSendTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string productbenefit_ids = 2;
 * @return {!Array<string>}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.getProductbenefitIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.setProductbenefitIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.addProductbenefitIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.clearProductbenefitIdsList = function() {
  return this.setProductbenefitIdsList([]);
};


/**
 * optional yourpass.mc.loyalty.common.v1.LocalizableString message = 3;
 * @return {?proto.yourpass.mc.loyalty.common.v1.LocalizableString}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.getMessage = function() {
  return /** @type{?proto.yourpass.mc.loyalty.common.v1.LocalizableString} */ (
    jspb.Message.getWrapperField(this, yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString, 3));
};


/**
 * @param {?proto.yourpass.mc.loyalty.common.v1.LocalizableString|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional yourpass.mc.loyalty.common.v1.LocalizableString content = 4;
 * @return {?proto.yourpass.mc.loyalty.common.v1.LocalizableString}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.getContent = function() {
  return /** @type{?proto.yourpass.mc.loyalty.common.v1.LocalizableString} */ (
    jspb.Message.getWrapperField(this, yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString, 4));
};


/**
 * @param {?proto.yourpass.mc.loyalty.common.v1.LocalizableString|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.hasContent = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional yourpass.mc.loyalty.common.v1.LocalizableString change_message = 5;
 * @return {?proto.yourpass.mc.loyalty.common.v1.LocalizableString}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.getChangeMessage = function() {
  return /** @type{?proto.yourpass.mc.loyalty.common.v1.LocalizableString} */ (
    jspb.Message.getWrapperField(this, yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString, 5));
};


/**
 * @param {?proto.yourpass.mc.loyalty.common.v1.LocalizableString|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.setChangeMessage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.clearChangeMessage = function() {
  return this.setChangeMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsRequest.prototype.hasChangeMessage = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse;
  return proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.CreateCampaignsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest;
  return proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderBy(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageToken(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string filter = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string order_by = 2;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.prototype.getOrderBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string page_token = 3;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.prototype.getPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.prototype.setPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsRequest.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignsList: jspb.Message.toObjectList(msg.getCampaignsList(),
    proto.yourpass.mc.loyalty.issuer.v1.Campaign.toObject, includeInstance),
    nextPageToken: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse;
  return proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yourpass.mc.loyalty.issuer.v1.Campaign;
      reader.readMessage(value,proto.yourpass.mc.loyalty.issuer.v1.Campaign.deserializeBinaryFromReader);
      msg.addCampaigns(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.yourpass.mc.loyalty.issuer.v1.Campaign.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Campaign campaigns = 1;
 * @return {!Array<!proto.yourpass.mc.loyalty.issuer.v1.Campaign>}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.prototype.getCampaignsList = function() {
  return /** @type{!Array<!proto.yourpass.mc.loyalty.issuer.v1.Campaign>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yourpass.mc.loyalty.issuer.v1.Campaign, 1));
};


/**
 * @param {!Array<!proto.yourpass.mc.loyalty.issuer.v1.Campaign>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.prototype.setCampaignsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Campaign=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.prototype.addCampaigns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.yourpass.mc.loyalty.issuer.v1.Campaign, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.prototype.clearCampaignsList = function() {
  return this.setCampaignsList([]);
};


/**
 * optional string next_page_token = 2;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ListCampaignsResponse.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: (f = msg.getCampaign()) && proto.yourpass.mc.loyalty.issuer.v1.Campaign.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest;
  return proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.yourpass.mc.loyalty.issuer.v1.Campaign;
      reader.readMessage(value,proto.yourpass.mc.loyalty.issuer.v1.Campaign.deserializeBinaryFromReader);
      msg.setCampaign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.yourpass.mc.loyalty.issuer.v1.Campaign.serializeBinaryToWriter
    );
  }
};


/**
 * optional Campaign campaign = 1;
 * @return {?proto.yourpass.mc.loyalty.issuer.v1.Campaign}
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.prototype.getCampaign = function() {
  return /** @type{?proto.yourpass.mc.loyalty.issuer.v1.Campaign} */ (
    jspb.Message.getWrapperField(this, proto.yourpass.mc.loyalty.issuer.v1.Campaign, 1));
};


/**
 * @param {?proto.yourpass.mc.loyalty.issuer.v1.Campaign|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.prototype.clearCampaign = function() {
  return this.setCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignRequest.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse;
  return proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.UpdateCampaignResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest;
  return proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse;
  return proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.CancelCampaignResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.Campaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sendTime: (f = msg.getSendTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    productbenefitId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    message: (f = msg.getMessage()) && yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.toObject(includeInstance, f),
    content: (f = msg.getContent()) && yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.toObject(includeInstance, f),
    changeMessage: (f = msg.getChangeMessage()) && yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.Campaign;
  return proto.yourpass.mc.loyalty.issuer.v1.Campaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSendTime(value);
      break;
    case 3:
      var value = /** @type {!proto.yourpass.mc.loyalty.issuer.v1.CampaignStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductbenefitId(value);
      break;
    case 5:
      var value = new yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString;
      reader.readMessage(value,yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 6:
      var value = new yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString;
      reader.readMessage(value,yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 7:
      var value = new yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString;
      reader.readMessage(value,yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.deserializeBinaryFromReader);
      msg.setChangeMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.Campaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSendTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getProductbenefitId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.serializeBinaryToWriter
    );
  }
  f = message.getChangeMessage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp send_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.getSendTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.setSendTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.clearSendTime = function() {
  return this.setSendTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.hasSendTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CampaignStatus status = 3;
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.CampaignStatus}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.getStatus = function() {
  return /** @type {!proto.yourpass.mc.loyalty.issuer.v1.CampaignStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.CampaignStatus} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string productbenefit_id = 4;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.getProductbenefitId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.setProductbenefitId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional yourpass.mc.loyalty.common.v1.LocalizableString message = 5;
 * @return {?proto.yourpass.mc.loyalty.common.v1.LocalizableString}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.getMessage = function() {
  return /** @type{?proto.yourpass.mc.loyalty.common.v1.LocalizableString} */ (
    jspb.Message.getWrapperField(this, yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString, 5));
};


/**
 * @param {?proto.yourpass.mc.loyalty.common.v1.LocalizableString|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional yourpass.mc.loyalty.common.v1.LocalizableString content = 6;
 * @return {?proto.yourpass.mc.loyalty.common.v1.LocalizableString}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.getContent = function() {
  return /** @type{?proto.yourpass.mc.loyalty.common.v1.LocalizableString} */ (
    jspb.Message.getWrapperField(this, yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString, 6));
};


/**
 * @param {?proto.yourpass.mc.loyalty.common.v1.LocalizableString|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.hasContent = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional yourpass.mc.loyalty.common.v1.LocalizableString change_message = 7;
 * @return {?proto.yourpass.mc.loyalty.common.v1.LocalizableString}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.getChangeMessage = function() {
  return /** @type{?proto.yourpass.mc.loyalty.common.v1.LocalizableString} */ (
    jspb.Message.getWrapperField(this, yourpass_mc_loyalty_common_v1_localizable_string_pb.LocalizableString, 7));
};


/**
 * @param {?proto.yourpass.mc.loyalty.common.v1.LocalizableString|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.setChangeMessage = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Campaign} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.clearChangeMessage = function() {
  return this.setChangeMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.Campaign.prototype.hasChangeMessage = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    granularity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fromTime: (f = msg.getFromTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    toTime: (f = msg.getToTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest;
  return proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.yourpass.mc.loyalty.issuer.v1.Granularity} */ (reader.readEnum());
      msg.setGranularity(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFromTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setToTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGranularity();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFromTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getToTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional Granularity granularity = 1;
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.Granularity}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.prototype.getGranularity = function() {
  return /** @type {!proto.yourpass.mc.loyalty.issuer.v1.Granularity} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.Granularity} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.prototype.setGranularity = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp from_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.prototype.getFromTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.prototype.setFromTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.prototype.clearFromTime = function() {
  return this.setFromTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.prototype.hasFromTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp to_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.prototype.getToTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.prototype.setToTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.prototype.clearToTime = function() {
  return this.setToTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsRequest.prototype.hasToTime = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    xList: jspb.Message.toObjectList(msg.getXList(),
    google_protobuf_timestamp_pb.Timestamp.toObject, includeInstance),
    productbenefitsList: jspb.Message.toObjectList(msg.getProductbenefitsList(),
    proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse;
  return proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.addX(value);
      break;
    case 2:
      var value = new proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats;
      reader.readMessage(value,proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.deserializeBinaryFromReader);
      msg.addProductbenefits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getXList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProductbenefitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.serializeBinaryToWriter
    );
  }
};


/**
 * repeated google.protobuf.Timestamp x = 1;
 * @return {!Array<!proto.google.protobuf.Timestamp>}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.prototype.getXList = function() {
  return /** @type{!Array<!proto.google.protobuf.Timestamp>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {!Array<!proto.google.protobuf.Timestamp>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.prototype.setXList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.google.protobuf.Timestamp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Timestamp}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.prototype.addX = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.google.protobuf.Timestamp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.prototype.clearXList = function() {
  return this.setXList([]);
};


/**
 * repeated ProductbenefitStats productbenefits = 2;
 * @return {!Array<!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats>}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.prototype.getProductbenefitsList = function() {
  return /** @type{!Array<!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats, 2));
};


/**
 * @param {!Array<!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.prototype.setProductbenefitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.prototype.addProductbenefits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductbenefitStatsResponse.prototype.clearProductbenefitsList = function() {
  return this.setProductbenefitsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    registrationsActiveList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats;
  return proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCreated(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRegistrationsActive(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getRegistrationsActiveList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 created = 2;
 * @return {!Array<number>}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.prototype.getCreatedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.prototype.setCreatedList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.prototype.addCreated = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.prototype.clearCreatedList = function() {
  return this.setCreatedList([]);
};


/**
 * repeated int64 registrations_active = 3;
 * @return {!Array<number>}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.prototype.getRegistrationsActiveList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.prototype.setRegistrationsActiveList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.prototype.addRegistrationsActive = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductbenefitStats.prototype.clearRegistrationsActiveList = function() {
  return this.setRegistrationsActiveList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest;
  return proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsRequest.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    ios: jspb.Message.getFieldWithDefault(msg, 2, 0),
    android: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse;
  return proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIos(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAndroid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIos();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAndroid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 ios = 2;
 * @return {number}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.prototype.getIos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.prototype.setIos = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 android = 3;
 * @return {number}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.prototype.getAndroid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetPlatformStatsResponse.prototype.setAndroid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest;
  return proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse;
  return proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = new proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats;
      reader.readMessage(value,proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ProductCampaignStats products = 2;
 * @return {!Array<!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats>}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats, 2));
};


/**
 * @param {!Array<!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductCampaignStatsResponse.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    consumed: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats;
  return proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConsumed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConsumed();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 consumed = 2;
 * @return {number}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.prototype.getConsumed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.prototype.setConsumed = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductCampaignStats.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest;
  return proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    productbenefitsList: jspb.Message.toObjectList(msg.getProductbenefitsList(),
    proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse;
  return proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 2:
      var value = new proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats;
      reader.readMessage(value,proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.deserializeBinaryFromReader);
      msg.addProductbenefits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getProductbenefitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.prototype.hasTime = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ProductBenefitUpdateStats productbenefits = 2;
 * @return {!Array<!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats>}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.prototype.getProductbenefitsList = function() {
  return /** @type{!Array<!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats, 2));
};


/**
 * @param {!Array<!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats>} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse} returns this
*/
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.prototype.setProductbenefitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats}
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.prototype.addProductbenefits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.GetProductBenefitUpdateStatsResponse.prototype.clearProductbenefitsList = function() {
  return this.setProductbenefitsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    consumed: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats;
  return proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConsumed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConsumed();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 consumed = 2;
 * @return {number}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.prototype.getConsumed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.prototype.setConsumed = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 limit = 3;
 * @return {number}
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats} returns this
 */
proto.yourpass.mc.loyalty.issuer.v1.ProductBenefitUpdateStats.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.yourpass.mc.loyalty.issuer.v1.OriginMethod = {
  ORIGIN_METHOD_UNKNOWN: 0,
  ORIGIN_METHOD_ISSUE: 1,
  ORIGIN_METHOD_CUSTOMER_INTEGRATION: 2,
  ORIGIN_METHOD_BULK_IMPORT: 3
};

/**
 * @enum {number}
 */
proto.yourpass.mc.loyalty.issuer.v1.Pricing = {
  PRICING_UNKNOWN: 0,
  PRICING_ELIGIBLE: 1,
  PRICING_CUSTOM: 2
};

/**
 * @enum {number}
 */
proto.yourpass.mc.loyalty.issuer.v1.CampaignStatus = {
  CAMPAIGN_STATUS_UNKNOWN: 0,
  CAMPAIGN_STATUS_SCHEDULED: 1,
  CAMPAIGN_STATUS_IN_PROGRESS: 2,
  CAMPAIGN_STATUS_DONE: 3,
  CAMPAIGN_STATUS_CANCELED: 4
};

/**
 * @enum {number}
 */
proto.yourpass.mc.loyalty.issuer.v1.Granularity = {
  GRANULARITY_DAILY: 0,
  GRANULARITY_WEEKLY: 1,
  GRANULARITY_MONTHLY: 2
};

goog.object.extend(exports, proto.yourpass.mc.loyalty.issuer.v1);
