import React, { CSSProperties } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { helpers } from '../../../constants';

type OnClick = (id?: string) => void;
type ActionButtonProps = { id?: string; style?: CSSProperties; name: string; onClick?: OnClick };

const useStyles = makeStyles(() =>
  createStyles({
    actionButton: {
      color: '#000',
      textDecoration: 'underline',
    },
  }),
);

export const ActionButton = ({ name, onClick = helpers.noop, id, style }: ActionButtonProps) => {
  const classes = useStyles();
  return (
    <>
      <Link
        style={style}
        component="button"
        onClick={() => onClick(id)}
        className={classes.actionButton}
      >
        {name}
      </Link>
    </>
  );
};
