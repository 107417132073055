import React, { useMemo } from 'react';
import { IconButton, IdLabel, LinkButton, Table, TemplateTooltip } from '../../../components';
import { useIssuer } from '../../../hooks';
import {
  Productbenefit,
  Benefit,
  OriginMethod,
} from '../../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { TableData } from '../../../components/Table';
import PreviewIcon from '@material-ui/icons/Visibility';

export function ProductBenefitTable() {
  const { issuer } = useIssuer();
  const data = useMemo(() => {
    const productsData: TableData = {
      head: [''],
      body: [],
    };

    const benefits = (issuer.getBenefits() || []).filter((pb: Benefit) => pb.getActive());

    const productBenefits = (issuer.getProductbenefitsList() || []).filter((pb: Productbenefit) =>
      pb.getActive(),
    );

    benefits.forEach((b: Benefit) => {
      productsData.head.push(b.getName()?.getDefault() || b.getId());
    });

    const benefitsData: TableData = {
      head: ['PRODUCT', 'TEMPLATE', 'TEMPLATE ID', '', 'DISTRIBUTION ISSUE LINK'],
      body: [],
    };

    productBenefits.forEach((pb: Productbenefit) => {
      benefitsData.body.push([
        issuer.getProductName(pb.getProductId()),
        issuer.getBenefitName(pb.getBenefitId()),
        <IdLabel key="templateId">{pb.getTemplateId()}</IdLabel>,
        <div key="tenplatePreview">
          <TemplateTooltip key="preview" id={pb.getTemplateId()}>
            <IconButton icon={<PreviewIcon />} text="Template preview" />
          </TemplateTooltip>
        </div>,
        pb.getOriginMethod() == OriginMethod.ORIGIN_METHOD_ISSUE ? (
          <LinkButton key="issuerUrl" url={pb.getIssueUrl()} />
        ) : (
          'Custom issued'
        ),
      ]);
    });

    return { benefitsData };
  }, [issuer]);

  return <Table heading={{ name: 'Product Templates' }} data={data.benefitsData} />;
}
