import React from 'react';
import Wrapper from '../../components/Wrapper';
import Divider from '../../components/common/Divider';
import { ROUTES } from '../../constants';
import Title from '../../components/common/Title';
import { ProductBenefitTable, ActiveProductTable, SpecialProductTable } from './components';

function ProductOverview() {
  return (
    <Wrapper selectedKey={ROUTES.productOverview.key}>
      <Title title="Product overview" dataTestId="product-overview-id" />
      <ActiveProductTable />
      <Divider />
      <SpecialProductTable />
      <Divider />
      <ProductBenefitTable />
    </Wrapper>
  );
}

export default ProductOverview;
