import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSnackbar } from 'notistack';

import Header, { toolBar } from './Header';

const innerForm = {
  width: 300,
  marginLeft: 20,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    paper: {
      margin: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    toolBar: {
      height: toolBar.height,
    },
    textField: {
      [`& .MuiOutlinedInput-root`]: {
        borderRadius: 25,
      },
      width: innerForm.width,
      marginLeft: innerForm.marginLeft,
    },
    label: {
      margin: 0,
      fontWeight: 'bold',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      alignSelf: 'flex-start',
    },
    submit: {
      textTransform: 'none',
      fontWeight: 'bold',
      color: '#fff',
      borderRadius: 25,
      width: innerForm.width,
      margin: theme.spacing(3, 0, 2),
    },
    forgotPass: {
      textAlign: 'center',
      width: innerForm.width,
    },
  }),
);

function Login() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [username, setUsername] = React.useState('');
  const handleUsernameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUsername(event.target.value as string);
  };

  const [password, setPassword] = React.useState('');
  const handlePasswordChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPassword(event.target.value as string);
  };

  const signIn = (e: React.FormEvent) => {
    e.preventDefault();
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      switch (xhr.status) {
        case 200:
          return window.location.reload();
        case 401:
          return enqueueSnackbar('Invalid credentials', { variant: 'error' });
        default:
          return console.error(xhr.responseText);
      }
    };
    xhr.open('POST', '/auth/login');
    xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
    xhr.send(JSON.stringify({ email: username, password: password }));
  };

  return (
    <div className={classes.root}>
      <Header />
      <main className={classes.content}>
        <Toolbar className={classes.toolBar} />
        <Container component="main" maxWidth="sm">
          <div className={classes.paper}>
            <Box marginY={5}>
              <Typography variant="h5">
                <Box textAlign="center" fontWeight="fontWeightBold">
                  Welcome to the ADMIN PORTAL for issuers
                </Box>
              </Typography>
              <Typography variant="subtitle1">
                <Box textAlign="center" fontWeight="fontWeightLight">
                  Login to administer your digital cards in Mastercard Loyalty Program.
                </Box>
              </Typography>
            </Box>
            <form className={classes.form} noValidate onSubmit={signIn}>
              <FormControlLabel
                label="USERNAME:"
                labelPlacement="start"
                className={classes.label}
                control={
                  <TextField
                    id="username"
                    name="username"
                    autoComplete="username"
                    value={username}
                    onChange={handleUsernameChange}
                    variant="outlined"
                    size="medium"
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    required
                    autoFocus
                  />
                }
              />
              <FormControlLabel
                label="PASSWORD:"
                labelPlacement="start"
                className={classes.label}
                control={
                  <TextField
                    id="password"
                    name="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handlePasswordChange}
                    variant="outlined"
                    size="medium"
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    required
                    type="password"
                  />
                }
              />

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                size="large"
                className={classes.submit}
              >
                Login
              </Button>
              <Box className={classes.forgotPass}>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Box>
            </form>
          </div>
        </Container>
      </main>
    </div>
  );
}

export default Login;
