import React, { useState } from 'react';
import Doughnut from '../common/charts/Doughnut';
import ReportTitle from './ReportTitle';

import { GroupBySelect } from '../common/selects/GroupBySelect';
import { ProductbenefitGroupBy, useProductBenefitsCreated } from '../../utils/report';
import { useControlStyles } from './styles';

export const Created = (): JSX.Element => {
  const [groupBy, setGroupBy] = useState(ProductbenefitGroupBy.PRODUCT);
  const { pending, data } = useProductBenefitsCreated(groupBy);
  const classes = useControlStyles();
  if (pending) {
    return <div />;
  }

  return (
    <React.Fragment>
      <ReportTitle
        title="Passes created"
        helpText={
          'When a cardholder clicks on "Add to wallet" on the cardholder landing page we create a new pass with an unique ID.  This report is a total of all unique Pass IDs created.'
        }
      />
      <div className={classes.root}>
        <GroupBySelect defaultValue={groupBy} onChange={setGroupBy} />
      </div>
      <Doughnut data={data} />
    </React.Fragment>
  );
};
