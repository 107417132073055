/* eslint-disable react/display-name */
import React from 'react';
import Wrapper from '../../components/Wrapper';
import Title from '../../components/common/Title';
import { Table } from '../../components';
import Divider from '../../components/common/Divider';
import { useSettingsData, changeLogoHeading } from './data';
function IssuerSettings() {
  const { changeLogoData, cancelData } = useSettingsData();

  return (
    <Wrapper>
      <Title title="Issuer settings" dataTestId="issuer-settings-id" />
      <Table heading={changeLogoHeading} data={changeLogoData} lastRight />
      <Divider width={700} />
      <Table data={cancelData} lastRight />
    </Wrapper>
  );
}

export default IssuerSettings;
