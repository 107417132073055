import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSnackbar } from 'notistack';
import { useUser } from '../../hooks';
import { ErrorText } from '../common';
import { isValidPassword } from './index';

const innerForm = {
  width: 300,
  marginLeft: 20,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    paper: {
      margin: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textField: {
      [`& .MuiOutlinedInput-root`]: {
        borderRadius: 25,
      },
      width: innerForm.width,
      marginLeft: innerForm.marginLeft,
    },
    label: {
      margin: 0,
      fontWeight: 'bold',
      [`& .MuiFormControlLabel-label `]: {
        width: 100,
      },
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      alignSelf: 'flex-start',
    },
    submit: {
      textTransform: 'none',
      fontWeight: 'bold',
      color: '#fff',
      borderRadius: 25,
      // width: innerForm.width,
      // margin: theme.spacing(2, 0, 1),
    },
    forgotPass: {
      textAlign: 'center',
      width: innerForm.width,
    },
  }),
);

function ChangePasswordDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { client, signOut } = useUser();

  const [error, setError] = React.useState<string | null>();

  const [currentpassword, setCurrentPwd] = React.useState('');
  const handleUsernameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentPwd(event.target.value as string);
  };

  const [newpassword, setNewPwd] = React.useState('');
  const handlePasswordChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNewPwd(event.target.value as string);
  };

  const onSubmit = async (e: React.FormEvent | React.MouseEvent<HTMLButtonElement>) => {
    let newError = null;

    if (!currentpassword) {
      newError = 'Please fill current password.';
    } else if (!newpassword) {
      newError = 'Please fill new password.';
    } else if (!isValidPassword(newpassword)) {
      newError =
        'New password must be between 8 and 128 characters, must contain at least one uppercase letter, one lowecase letter and at least one special character.';
    } else if (currentpassword === newpassword) {
      newError = 'New password cannot be the same as old.';
    }

    if (!newError) {
      e.preventDefault();
      try {
        await client?.changePassword(currentpassword, newpassword);
        enqueueSnackbar('Password changed', { variant: 'success' });
        signOut && (await signOut());
        onClose();
      } catch (e) {
        console.error(e);
        newError =
          'Change password failed. Your current password is not valid or new password is not secure. New password must be between 8 and 128 characters, must contain at least one uppercase letter, one lowecase letter and at least one special character.';
        enqueueSnackbar(
          'Change password failed. Your current password is not valid or new password is not secure. New password must be between 8 and 128 characters, must contain at least one uppercase letter, one lowecase letter and at least one special character.',
          { variant: 'error' },
        );
      }
    }
    setError(newError);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Change password</DialogTitle>
      <DialogContent>
        <Container component="main" maxWidth="sm">
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <div className={classes.paper}>
              <FormControlLabel
                label="CURRENT PASSWORD:"
                labelPlacement="start"
                className={classes.label}
                control={
                  <TextField
                    id="currentpassword"
                    name="currentpassword"
                    autoComplete="current-password"
                    value={currentpassword}
                    onChange={handleUsernameChange}
                    variant="outlined"
                    size="medium"
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    required
                    autoFocus
                    type="password"
                  />
                }
              />
              <FormControlLabel
                label="NEW PASSWORD:"
                labelPlacement="start"
                className={classes.label}
                control={
                  <TextField
                    id="newpassword"
                    name="newpassword"
                    autoComplete="new-password"
                    value={newpassword}
                    onChange={handlePasswordChange}
                    variant="outlined"
                    size="medium"
                    fullWidth
                    margin="normal"
                    className={classes.textField}
                    required
                    type="password"
                  />
                }
              />
              {error && <ErrorText error={error} />}
            </div>
          </form>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" className={classes.submit} onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={onSubmit}
          className={classes.submit}
        >
          Change password
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangePasswordDialog;
