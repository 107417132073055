import React from 'react';
import { BottomLabeled } from '../common';
import { createStyles, makeStyles } from '@material-ui/core';
import { ProductBenefitUpdateConsumption, CampaignsEligibleConsumption } from './consumption';

const useStyles = makeStyles(() =>
  createStyles({
    row: { display: 'flex', flexWrap: 'wrap', margin: -30 },
    rowItem: { padding: 30 },
  }),
);

export function CampaignsConsumption() {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      <div className={classes.rowItem}>
        <BottomLabeled label={'Eligible'}>
          <CampaignsEligibleConsumption />
        </BottomLabeled>
      </div>
      <div className={classes.rowItem}>
        <BottomLabeled label={'Custom'}>
          <ProductBenefitUpdateConsumption />
        </BottomLabeled>
      </div>
    </div>
  );
}
