import React, { useState } from 'react';
import ReportTitle from './ReportTitle';
import Bar from '../common/charts/Bar';
import { TimelineFilter, TimelineFilterState } from './TimelineFilter';
import { ProductbenefitGroupBy } from '../../utils/report';
import { Granularity } from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { useCreationIncrementTimeline } from '../../utils/report';
import moment from 'moment';
import { getColorForIndex } from '../../utils';
import { Legend, LegendItem } from '../common/charts/Legend';
import { ChartDataSets } from 'chart.js';
import { useControlStyles } from './styles';

const initialState = {
  groupBy: ProductbenefitGroupBy.PRODUCT,
  from: moment().add(-6, 'day').utc().startOf('day'),
  to: moment().utc().endOf('day'),
  granularity: Granularity.GRANULARITY_DAILY,
};

export const TimeLine = (): JSX.Element => {
  const [state, setState] = useState<TimelineFilterState>(initialState);
  const classes = useControlStyles();

  const { pending, data } = useCreationIncrementTimeline(
    state.from,
    state.to,
    state.granularity,
    state.groupBy,
  );

  if (pending || !data) {
    return <div />;
  }

  return (
    <React.Fragment>
      <ReportTitle
        title="Pass creation evolution"
        helpText={'Shows the creation of passes over time'}
      />
      <div className={classes.root}>
        <TimelineFilter
          value={state}
          onChange={(d) => {
            setState(d);
          }}
        />
      </div>

      <Bar data={data} resposive={true} height={100} />
      {data.datasets && (
        <Legend
          items={data.datasets.map(
            (ds: ChartDataSets, i: number): LegendItem => ({
              v: '',
              l: ds.label || `${i}`,
              c: getColorForIndex(i),
            }),
          )}
        />
      )}
    </React.Fragment>
  );
};
