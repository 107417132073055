import { IssuerServiceClient } from '../../proto/yourpass/mc/loyalty/issuer/v1/Issuer_serviceServiceClientPb';
import {
  GetViewerRequest,
  GetViewerResponse,
  ListCampaignsRequest,
  ListCampaignsResponse,
  GetProductbenefitStatsRequest,
  GetProductbenefitStatsResponse,
  GetPlatformStatsRequest,
  GetPlatformStatsResponse,
  GetProductCampaignStatsRequest,
  GetProductCampaignStatsResponse,
  CreateCampaignsRequest,
  CreateCampaignsResponse,
  CancelCampaignResponse,
  CancelCampaignRequest,
  Granularity,
  ChangePasswordRequest,
  ChangePasswordResponse,
  GetTemplateLayoutRequest,
  GetTemplateLayoutResponse,
  GetProductBenefitUpdateStatsRequest,
  GetProductBenefitUpdateStatsResponse,
} from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import { Error as GrpcError, StatusCode } from 'grpc-web';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

export const getCsrf = (): string | null => {
  const metas = document.getElementsByTagName('meta');
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === 'csrf-token') {
      return metas[i].getAttribute('content');
    }
  }
  return null;
};

export default class ApiClient {
  private client: IssuerServiceClient;
  private csrf: string;

  constructor(csrf: string) {
    this.client = new IssuerServiceClient('', null, null);
    this.csrf = csrf;
  }

  getViewer(): Promise<GetViewerResponse> {
    return new Promise<GetViewerResponse>((resolve, reject) => {
      this.client.getViewer(
        new GetViewerRequest(),
        { 'x-csrf-token': this.csrf },
        (err: GrpcError, response: GetViewerResponse) => {
          if (err) {
            let message = err.message;
            if (err.code === StatusCode.UNAUTHENTICATED) {
              message = 'unauthenticated';
            }
            // console.error(message);
            reject(message);
            return;
          }
          resolve(response);
        },
      );
    });
  }

  getCampaigns(pageToken: string): Promise<ListCampaignsResponse> {
    return new Promise<ListCampaignsResponse>((resolve, reject) => {
      const request = new ListCampaignsRequest();
      request.setPageToken(pageToken);

      this.client.listCampaigns(
        request,
        { 'x-csrf-token': this.csrf },
        (err: GrpcError, response: ListCampaignsResponse) => {
          if (err) {
            let message = err.message;
            if (err.code === StatusCode.UNAUTHENTICATED) {
              message = 'unauthenticated';
            }
            // console.error(message);
            reject(message);
            return;
          }
          resolve(response);
        },
      );
    });
  }

  getTemplateLayout(id: string): Promise<GetTemplateLayoutResponse> {
    return new Promise<GetTemplateLayoutResponse>((resolve, reject) => {
      const request = new GetTemplateLayoutRequest();
      request.setTemplateId(id);

      this.client.getTemplateLayout(
        request,
        { 'x-csrf-token': this.csrf },
        (err: GrpcError, response: GetTemplateLayoutResponse) => {
          if (err) {
            let message = err.message;
            if (err.code === StatusCode.UNAUTHENTICATED) {
              message = 'unauthenticated';
            }
            // console.error(message);
            reject(message);
            return;
          }
          resolve(response);
        },
      );
    });
  }

  getProductbenefitStats(opts?: {
    from: Date;
    to: Date;
    granularity: Granularity;
  }): Promise<GetProductbenefitStatsResponse> {
    return new Promise<GetProductbenefitStatsResponse>((resolve, reject) => {
      const request = new GetProductbenefitStatsRequest();

      if (opts) {
        if (opts.from) {
          const f = new Timestamp();
          f.fromDate(opts.from);
          request.setFromTime(f);
        }
        if (opts.to) {
          const f = new Timestamp();
          f.fromDate(opts.to);
          request.setToTime(f);
        }
        if (opts.granularity) {
          request.setGranularity(opts.granularity);
        }
      }

      this.client.getProductbenefitStats(
        request,
        { 'x-csrf-token': this.csrf },
        (err: GrpcError, response: GetProductbenefitStatsResponse) => {
          if (err) {
            let message = err.message;
            if (err.code === StatusCode.UNAUTHENTICATED) {
              message = 'unauthenticated';
            }
            // console.error(message);
            reject(message);
            return;
          }
          resolve(response);
        },
      );
    });
  }

  getPlatformStats(): Promise<GetPlatformStatsResponse> {
    return new Promise<GetPlatformStatsResponse>((resolve, reject) => {
      const request = new GetPlatformStatsRequest();
      this.client.getPlatformStats(
        request,
        { 'x-csrf-token': this.csrf },
        (err: GrpcError, response: GetPlatformStatsResponse) => {
          if (err) {
            let message = err.message;
            if (err.code === StatusCode.UNAUTHENTICATED) {
              message = 'unauthenticated';
            }
            // console.error(message);
            reject(message);
            return;
          }
          resolve(response);
        },
      );
    });
  }

  getProductCampaignStats(): Promise<GetProductCampaignStatsResponse> {
    return new Promise<GetProductCampaignStatsResponse>((resolve, reject) => {
      const request = new GetProductCampaignStatsRequest();
      this.client.getProductCampaignStats(
        request,
        { 'x-csrf-token': this.csrf },
        (err: GrpcError, response: GetProductCampaignStatsResponse) => {
          if (err) {
            let message = err.message;
            if (err.code === StatusCode.UNAUTHENTICATED) {
              message = 'unauthenticated';
            }
            // console.error(message);
            reject(message);
            return;
          }
          resolve(response);
        },
      );
    });
  }

  getProductBenefitUpdateStats(): Promise<GetProductBenefitUpdateStatsResponse> {
    return new Promise<GetProductBenefitUpdateStatsResponse>((resolve, reject) => {
      const request = new GetProductBenefitUpdateStatsRequest();
      this.client.getProductBenefitUpdateStats(
        request,
        { 'x-csrf-token': this.csrf },
        (err: GrpcError, response: GetProductBenefitUpdateStatsResponse) => {
          if (err) {
            let message = err.message;
            if (err.code === StatusCode.UNAUTHENTICATED) {
              message = 'unauthenticated';
            }
            // console.error(message);
            reject(message);
            return;
          }
          resolve(response);
        },
      );
    });
  }

  createCampaign(request: CreateCampaignsRequest): Promise<CreateCampaignsResponse> {
    return new Promise<CreateCampaignsResponse>((resolve, reject) => {
      this.client.createCampaigns(
        request,
        { 'x-csrf-token': this.csrf },
        (err: GrpcError, response: CreateCampaignsResponse) => {
          if (err) {
            let message = err.message;
            if (err.code === StatusCode.UNAUTHENTICATED) {
              message = 'unauthenticated';
            }
            // console.error(message);
            reject(message);
            return;
          }
          resolve(response);
        },
      );
    });
  }

  cancelCampaign(campaignId: string): Promise<CancelCampaignResponse> {
    return new Promise<CancelCampaignResponse>((resolve, reject) => {
      const request = new CancelCampaignRequest();
      request.setId(campaignId);
      this.client.cancelCampaign(
        request,
        { 'x-csrf-token': this.csrf },
        (err: GrpcError, response: CreateCampaignsResponse) => {
          if (err) {
            let message = err.message;
            if (err.code === StatusCode.UNAUTHENTICATED) {
              message = 'unauthenticated';
            }
            // console.error(message);
            reject(message);
            return;
          }
          resolve(response);
        },
      );
    });
  }

  changePassword(currentPwd: string, newPwd: string): Promise<ChangePasswordResponse> {
    return new Promise<ChangePasswordResponse>((resolve, reject) => {
      const request = new ChangePasswordRequest();
      request.setCurrentPassword(currentPwd);
      request.setNewPassword(newPwd);
      this.client.changePassword(
        request,
        { 'x-csrf-token': this.csrf },
        (err: GrpcError, response: CreateCampaignsResponse) => {
          if (err) {
            let message = err.message;
            if (err.code === StatusCode.UNAUTHENTICATED) {
              message = 'unauthenticated';
            }
            console.error(message);
            reject(message);
            return;
          }
          resolve(response);
        },
      );
    });
  }
}
