// source: yourpass/mc/loyalty/common/v1/pk_images.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.yourpass.mc.loyalty.common.v1.PkImages', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.yourpass.mc.loyalty.common.v1.PkImages = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.yourpass.mc.loyalty.common.v1.PkImages, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.yourpass.mc.loyalty.common.v1.PkImages.displayName = 'proto.yourpass.mc.loyalty.common.v1.PkImages';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.toObject = function(opt_includeInstance) {
  return proto.yourpass.mc.loyalty.common.v1.PkImages.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.yourpass.mc.loyalty.common.v1.PkImages} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.toObject = function(includeInstance, msg) {
  var f, obj = {
    iconMap: (f = msg.getIconMap()) ? f.toObject(includeInstance, undefined) : [],
    logoMap: (f = msg.getLogoMap()) ? f.toObject(includeInstance, undefined) : [],
    stripMap: (f = msg.getStripMap()) ? f.toObject(includeInstance, undefined) : [],
    thumbnailMap: (f = msg.getThumbnailMap()) ? f.toObject(includeInstance, undefined) : [],
    footerMap: (f = msg.getFooterMap()) ? f.toObject(includeInstance, undefined) : [],
    backgroundMap: (f = msg.getBackgroundMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.yourpass.mc.loyalty.common.v1.PkImages}
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.yourpass.mc.loyalty.common.v1.PkImages;
  return proto.yourpass.mc.loyalty.common.v1.PkImages.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.yourpass.mc.loyalty.common.v1.PkImages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.yourpass.mc.loyalty.common.v1.PkImages}
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getIconMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 2:
      var value = msg.getLogoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = msg.getStripMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = msg.getThumbnailMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 5:
      var value = msg.getFooterMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 6:
      var value = msg.getBackgroundMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.yourpass.mc.loyalty.common.v1.PkImages.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.yourpass.mc.loyalty.common.v1.PkImages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIconMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getLogoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getStripMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getThumbnailMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getFooterMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getBackgroundMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> icon = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.getIconMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yourpass.mc.loyalty.common.v1.PkImages} returns this
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.clearIconMap = function() {
  this.getIconMap().clear();
  return this;};


/**
 * map<string, string> logo = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.getLogoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yourpass.mc.loyalty.common.v1.PkImages} returns this
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.clearLogoMap = function() {
  this.getLogoMap().clear();
  return this;};


/**
 * map<string, string> strip = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.getStripMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yourpass.mc.loyalty.common.v1.PkImages} returns this
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.clearStripMap = function() {
  this.getStripMap().clear();
  return this;};


/**
 * map<string, string> thumbnail = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.getThumbnailMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yourpass.mc.loyalty.common.v1.PkImages} returns this
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.clearThumbnailMap = function() {
  this.getThumbnailMap().clear();
  return this;};


/**
 * map<string, string> footer = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.getFooterMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yourpass.mc.loyalty.common.v1.PkImages} returns this
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.clearFooterMap = function() {
  this.getFooterMap().clear();
  return this;};


/**
 * map<string, string> background = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.getBackgroundMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.yourpass.mc.loyalty.common.v1.PkImages} returns this
 */
proto.yourpass.mc.loyalty.common.v1.PkImages.prototype.clearBackgroundMap = function() {
  this.getBackgroundMap().clear();
  return this;};


goog.object.extend(exports, proto.yourpass.mc.loyalty.common.v1);
