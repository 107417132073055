import {
  GetViewerResponse,
  Benefit,
  Product,
  Productbenefit,
  DownloadableFile,
  Issuer as IssuerApi,
} from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';

export default class Issuer {
  private benefits: Benefit[];
  private products: Product[];
  private productBenefits: Productbenefit[];
  private productsMap: { [key: string]: string };
  private benefitsMap: { [key: string]: string };
  private productBenefitsMap: { [key: string]: Productbenefit };
  private editableChangeMessage: boolean;
  private downloads: DownloadableFile[];
  private issuer: IssuerApi;

  constructor(d: GetViewerResponse | null) {
    this.benefits = d?.getBenefitsList() || [];
    this.products = d?.getProductsList() || [];

    this.benefitsMap = {};
    this.benefits.forEach((b: Benefit) => {
      this.benefitsMap[b.getId()] = b.getName()?.getDefault() || b.getId();
    });

    this.productsMap = {};
    this.products.forEach((p: Product) => {
      this.productsMap[p.getId()] = p.getName()?.getDefault() || p.getId();
    });

    this.productBenefits = d?.getProductbenefitsList() || [];
    this.productBenefitsMap = {};
    this.productBenefits.forEach((p: Productbenefit) => {
      this.productBenefitsMap[p.getId()] = p;
    });
    this.downloads = d?.getDownloadsList() || [];
    this.issuer = d?.getIssuer() || new IssuerApi();
    this.editableChangeMessage = !!d?.getIssuer()?.getEditableChangeMessage();
  }

  public getProductbenefit(id: string): Productbenefit {
    return this.productBenefitsMap[id];
  }

  public getProductbenefitName(id: string) {
    const pb = this.getProductbenefit(id);
    if (pb) {
      return `${this.productsMap[pb.getProductId()]} ${this.benefitsMap[pb.getBenefitId()]}`;
    }

    return id;
  }

  public getProductName(id: string) {
    return this.productsMap[id] || id;
  }

  public getBenefitName(id: string) {
    return this.benefitsMap[id] || id;
  }

  public getBenefits(): Benefit[] {
    return this.benefits;
  }
  public getProductsList(): Product[] {
    return this.products;
  }
  public getProductbenefitsList(): Productbenefit[] {
    return this.productBenefits;
  }

  public hasEditableChangeMessage(): boolean {
    return this.editableChangeMessage;
  }

  public getDownloads() {
    return this.downloads;
  }

  public getIssuer() {
    return this.issuer;
  }

  public getLanguages(): string[] {
    return this.issuer.getLanguagesList();
  }

  public getDefualtLanguage(): string {
    return 'en';
  }
}
