/* eslint-disable react/display-name */
import React from 'react';
import Star from '@material-ui/icons/Star';
import Lock from '@material-ui/icons/Lock';
import Person from '@material-ui/icons/Person';
import { useUser, useIssuer } from '../../hooks';

const changeLogoHeading = {
  name: 'Change logo on your passes',
  Icon: () => <Star />,
};

const passwordHeading = {
  name: 'Password',
  Icon: () => <Lock />,
};

const profileHeading = {
  name: 'Profile',
  Icon: () => <Person />,
};

function useAccountData() {
  const value = useUser();
  const { issuer } = useIssuer();

  const profileData = {
    head: [],
    body: [
      ['Full Name', value.viewer?.getDisplayName() || '', ''],
      ['Email', value.viewer?.getEmail() || '', ''],
      ['Business Entity', issuer.getIssuer().getEntity()?.getDefault() || '', ''],
    ],
  };

  return { profileData };
}

export { useAccountData, changeLogoHeading, passwordHeading, profileHeading };
