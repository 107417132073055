import React from 'react';
import { DatePicker, GroupBySelect, GranularitySelect } from '../common';
import { ProductbenefitGroupBy } from '../../utils/report';
import { Granularity } from '../../proto/yourpass/mc/loyalty/issuer/v1/issuer_service_pb';
import moment, { Moment } from 'moment';
import { makeStyles, Theme } from '@material-ui/core';

export interface TimelineFilterState {
  groupBy: ProductbenefitGroupBy;
  from: Moment;
  to: Moment;
  granularity: Granularity;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      margin: 0,
      marginRight: theme.spacing(2),
    },
  },
}));
export const TimelineFilter = ({
  value,
  onChange,
}: {
  value: TimelineFilterState;
  onChange: (d: TimelineFilterState) => void;
}): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <GroupBySelect
        defaultValue={value.groupBy}
        onChange={(gb) => {
          onChange({ ...value, groupBy: gb });
        }}
      />
      <DatePicker
        label="From"
        value={value.from}
        dense={true}
        onChange={(date: Moment | null) => {
          date && onChange({ ...value, from: date.utc() });
        }}
        maxDate={value.to}
        maxDateMessage="Date cannot be lower then to"
        disableFuture={true}
        shouldDisableDate={(i: Moment | null) => {
          if (i) {
            if (value.granularity === Granularity.GRANULARITY_MONTHLY) {
              return i.utc().date() !== 1;
            } else if (value.granularity === Granularity.GRANULARITY_WEEKLY) {
              return i.utc().isoWeekday() !== 7;
            }
          }
          return false;
        }}
      />
      <DatePicker
        label="To"
        value={value.to}
        dense={true}
        onChange={(date: Moment | null) => {
          date && onChange({ ...value, to: date.utc() });
        }}
        minDate={value.from}
        minDateMessage="Date cannot be higher then from"
        // disableFuture={true}
        shouldDisableDate={(i: Moment | null) => {
          if (i) {
            if (value.granularity === Granularity.GRANULARITY_MONTHLY) {
              return i.utc().date() !== moment(i).endOf('month').date();
            }
            if (value.granularity === Granularity.GRANULARITY_WEEKLY) {
              return i.utc().isoWeekday() !== 6;
            }
          }
          return false;
        }}
      />

      <GranularitySelect
        value={value.granularity}
        onChange={(g) => {
          let from = value.from;
          let to = value.to;

          // TODO - check if not dates(from/to) are in future
          if (g === Granularity.GRANULARITY_MONTHLY) {
            from = moment(from).startOf('month');
            to = moment(to).endOf('month');
          }
          if (g === Granularity.GRANULARITY_WEEKLY) {
            from = moment(from).startOf('week');
            to = moment(to).endOf('week');
          }

          onChange({ ...value, from, to, granularity: g });
        }}
      />
    </div>
  );
};
