import * as chartjs from 'chart.js';
import React, { useEffect, useState } from 'react';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';
import { DoughnutLegend } from './Legend';

type DougnuthChartOption = chartjs.ChartOptions & { center: boolean };

chartjs.Chart.pluginService.register({
  beforeDraw: function (chart: chartjs & chartjs.DoughnutModel) {
    const chartOptions = chart.config.options as DougnuthChartOption;
    if (chartOptions.center && chart.data.datasets && chart.ctx) {
      //Get ctx from string
      const ctx = chart.ctx;
      const meta = chart.getDatasetMeta(0);
      const data = chart.data.datasets[0].data as number[];
      const txt = data
        .filter((e, i) => !meta.data[i].hidden)
        .reduce((p, c) => p + c, 0)
        .toLocaleString();
      //Start with a base font of 30px
      ctx.font = '30px Arial';

      const stringWidth = ctx.measureText(txt).width;
      const maxWidth = chart.innerRadius * 1.4;
      const maxHeight = chart.innerRadius * 1.4;
      // Find out how much the font can grow in width.
      const widthRatio = maxWidth / stringWidth;
      const maxFontSize = Math.floor(30 * widthRatio);
      //Set font settings to draw it correctly.
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = Math.min(maxFontSize, maxHeight) + 'px Arial';
      ctx.fillStyle = '#000';
      //Draw text in center
      ctx.fillText(
        txt,
        (chart.chartArea.left + chart.chartArea.right) / 2,
        (chart.chartArea.top + chart.chartArea.bottom) / 2,
      );
    }
  },
});

export type ChartDataFunction<T extends chartjs.ChartData> = (element: HTMLElement) => T;
export type ChartData<T extends chartjs.ChartData> = ChartDataFunction<T> | T;

interface Props {
  data: ChartData<chartjs.ChartData>;
  text?: string;
  withWhole?: boolean;
}

function Doughnut({ text, data, withWhole }: Props) {
  const additionalOption = withWhole
    ? { rotation: 0.75 * Math.PI, circumference: 1.5 * Math.PI }
    : {};

  const [ref, setRef] = useState<DoughnutChart | null>(null);

  const [legend, setLegend] = useState<JSX.Element | null>(null);

  useEffect(() => {
    if (ref) {
      ref.chartInstance.generateLegend();
    }
  }, [ref, data]);

  const opts: DougnuthChartOption = {
    responsive: false,
    center: true,
    legend: { display: false, position: 'bottom', labels: { boxWidth: 12 }, align: 'center' },
    legendCallback: (chart: Chart): string => {
      setLegend(<DoughnutLegend chart={chart} />);
      return '';
    },
    title: { text: text || '', position: 'bottom', display: false },
    ...additionalOption,
  };

  return (
    <div>
      <DoughnutChart
        ref={(element) => setRef(element)}
        width={200}
        height={200}
        options={opts}
        data={data}
      />
      {legend}
    </div>
  );
}

export default Doughnut;
