import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useTemplateLayout } from '../../hooks';
import { CircularProgress } from '@material-ui/core';
import {
  PkpassTemplatePreview,
  ImageReferenceMap,
  JsonTemplate,
  PkpassImages,
} from '@yourpass/react-lib';
import * as jspb from 'google-protobuf';

const TemplateTooltip = (props: React.PropsWithChildren<{ id: string }>): JSX.Element => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={<Content open={open} id={props.id} />}
    >
      <div>{props.children}</div>
    </Tooltip>
  );
};

const Content = (props: { id: string; open: boolean }) => {
  const { isLoading, data } = useTemplateLayout(props.id);

  let content = <CircularProgress />;

  if (!(isLoading || data === null)) {
    const template = {
      images: {
        icon: parseImage(data.getImages()?.getIconMap()),
        strip: parseImage(data.getImages()?.getStripMap()),
        background: parseImage(data.getImages()?.getBackgroundMap()),
        footer: parseImage(data.getImages()?.getFooterMap()),
        logo: parseImage(data.getImages()?.getLogoMap()),
        thumbnail: parseImage(data.getImages()?.getThumbnailMap()),
      } as ImageReferenceMap,
      language: 'en',
      languages: ['en'],
      jsonTemplate: data.getTemplateLayout()?.toJavaScript() as JsonTemplate,
    };
    content = (
      <PkpassTemplatePreview
        template={template}
        language={'en'}
        imagesConvertor={(images) => images as PkpassImages}
        scale={0.7}
      />
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: 224,
          height: 415,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {content}
      </div>
      <p style={{ textAlign: 'center' }}>{props.id}</p>
    </div>
  );
};

export default TemplateTooltip;

const parseImage = (
  m: jspb.Map<string, string> | undefined,
): { [key: string]: string } | undefined => {
  if (m === undefined) {
    return undefined;
  }

  return Object.fromEntries(m.toObject());
};
